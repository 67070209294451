// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/silk-serif/silk_serif_light-webfont.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/silk-serif/silk_serif_bold-webfont.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Silk Serif\";font-style:normal;font-weight:300;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"Silk Serif\";font-style:normal;font-weight:700;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}.bow-page:not(.bow-immobili){overflow:hidden}.page-enter-active,.page-leave-active{transition:opacity .75s}.page-enter,.page-leave-to{opacity:0}.has-scroll-smooth body{overflow:hidden}.has-scroll-smooth .js-locomotive{min-height:100%;width:100%}[data-scroll-container],[data-scroll-section]{transform-style:preserve-3d;will-change:transform}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
