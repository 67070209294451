var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{tag:"component",class:[
        'a-ring',
        'border-2 border-secondary border-solid',
        _vm.className
            ? _vm.className
            : [
                  'absolute left-1/2 top-1/2 transform',
                  '-translate-x-1/2 -translate-y-1/2 origin-center',
                  'h-full pointer-events-none w-full' ],
        _vm.radius ? 'rounded-[50vw]' : '' ]})}
var staticRenderFns = []

export { render, staticRenderFns }