//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { checkBreakpoint } from "~/helpers";

export default {
    name: "LocomotiveScroll",

    directives: {
        locomotive: {
            inserted(el, binding, vnode) {
                vnode.context.locomotive = new vnode.context.LocomotiveScroll({
                    el,
                    ...binding.value.options,
                });
                vnode.context.locomotive.on("scroll", (e) => {
                    vnode.context.onScroll(e);
                    vnode.context.$emit("scroll");
                });
                vnode.context.$emit("init");
            },

            unbind(el, binding, vnode) {
                vnode.context.locomotive.destroy();
                vnode.context.locomotive = undefined;
            },
        },
    },

    props: {
        gettedOptions: {
            type: Object,
            default: () => {},
        },

        parallax: {
            default: () => ({
                active: false,
                top: 0,
            }),
            type: Object,
        },

        sections: {
            default: null,
            type: Array,
        },
    },

    data: () => ({
        checkBreakpointDownXl: false,
        devicePixelRatio: {
            current: 1,
            previous: 1,
        },
        locomotive: undefined,
        defaultOptions: {
            smooth: true,
        },
    }),

    computed: {
        options() {
            return { ...this.defaultOptions, ...this.gettedOptions };
        },

        ...mapState({
            scroll: ({ scroll }) => scroll.scroll,
            isLocomotiveActive: ({ scroll }) => scroll.isLocomotiveActive,
            limit: ({ scroll }) => scroll.limit,
            isPageImmobili: ({ ui }) => ui.isPageImmobili,
        }),

        ...mapGetters([
            "scroll/GET_SCROLL",
            "scroll/GET_IS_LOCOMOTIVE_ACTIVE",
            "scroll/GET_LIMIT",
            "ui/GET_IS_PAGE_IMMOBILI",
        ]),
    },

    /**
     *  You can remove mounted hook if you don't needs custom updates
     *  Call this.$nuxt.$emit('update-locomotive') wherever you want
     */
    mounted() {
        this.checkBreakpointDownXl = checkBreakpoint.down("xl");

        this.devicePixelRatio.current =
            window.devicePixelRatio ||
            window.screen.availWidth / document.documentElement.clientWidth;

        window.addEventListener("resize", () => {
            this.devicePixelRatio.previous = this.devicePixelRatio.current;
            this.devicePixelRatio.current =
                window.devicePixelRatio ||
                window.screen.availWidth / document.documentElement.clientWidth;
        });

        this.$nuxt.$on("update-locomotive", () => {
            this?.locomotive?.update();
        });

        this.$nuxt.$on("scrollTo", (anchorTop) => {
            // console.log(anchorTop);
            this?.locomotive?.scrollTo(anchorTop, { duration: 1000 });
        });

        this.$store.dispatch("scroll/updateIsLocomotiveActive", true);
    },

    destroyed() {
        this.$store.dispatch("scroll/updateIsLocomotiveActive", false);
    },

    methods: {
        ...mapActions([
            "scroll/UPDATE_SCROLL",
            "scroll/updateIsLocomotiveActive",
            "scroll/UPDATE_LIMIT",
        ]),

        onScroll(e) {
            if (
                typeof this.$store._actions["scroll/UPDATE_SCROLL"] !==
                "undefined"
            ) {
                let way = "up";

                if (e.scroll.y > this.scroll.current.y) {
                    way = "down";
                }

                if (e.scroll.y === 0) {
                    way = "up";
                }

                if (e.scroll.y >= e.limit.y) {
                    way = "down";
                }

                this.$store.dispatch("scroll/UPDATE_SCROLL", {
                    isScrolling: this.locomotive.scroll.isScrolling,
                    previous: {
                        ...this.scroll.current,
                    },
                    current: {
                        ...e.scroll, // x, y
                        way,
                    },
                });

                this.$store.dispatch("scroll/UPDATE_LIMIT", {
                    previous: {
                        ...this.limit.current,
                    },
                    current: { ...e.limit },
                });

                if (this.isPageImmobili === true) {
                    if (document.querySelector(".o-header__wrapper")) {
                        document.querySelector(
                            ".o-header__wrapper"
                        ).style.transform = "translateY(0px)";
                    }

                    if (
                        document.querySelector(".o-footer--desktop") &&
                        checkBreakpoint.up("xl")
                    ) {
                        document.querySelector(
                            ".o-footer--desktop"
                        ).style.transform = "translateY(0px)";
                    }

                    return;
                }

                const wrapperHeight = document
                    .querySelector(".b-scroll__wrapper")
                    .getBoundingClientRect().height;

                if (Math.round(wrapperHeight) > window.innerHeight + 10) {
                    if (way === "up") {
                        if (document.querySelector(".o-header__wrapper")) {
                            document.querySelector(
                                ".o-header__wrapper"
                            ).style.transform = "translateY(0px)";
                        }

                        if (
                            document.querySelector(".o-footer--desktop") &&
                            checkBreakpoint.up("xl")
                        ) {
                            document.querySelector(
                                ".o-footer--desktop"
                            ).style.transform = "translateY(0px)";
                        }
                    } else {
                        if (document.querySelector(".o-header__wrapper")) {
                            document.querySelector(
                                ".o-header__wrapper"
                            ).style.transform = "translateY(-100%)";
                        }

                        if (
                            document.querySelector(".o-footer--desktop") &&
                            checkBreakpoint.up("xl")
                        ) {
                            document.querySelector(
                                ".o-footer--desktop"
                            ).style.transform = "translateY(100%)";
                        }
                    }
                } else {
                    if (document.querySelector(".o-header__wrapper")) {
                        document.querySelector(
                            ".o-header__wrapper"
                        ).style.transform = "translateY(0px)";
                    }

                    if (
                        document.querySelector(".o-footer--desktop") &&
                        checkBreakpoint.up("xl")
                    ) {
                        document.querySelector(
                            ".o-footer--desktop"
                        ).style.transform = "translateY(0px)";
                    }
                }
            }
        },
    },
};
