/**
 * Convert HEX color code in RGB version
 *
 * @author Federico Corini <fcorini@bizonweb.it>
 *
 * @param {string} hexColor - The hexadecimal code of color to convert, default is white
 * @returns {string} - The color in RGB
 *
 */
export default function (hexColor = "#ffffff") {
    if (hexColor.length === 6) {
        const aRgbHex = hexColor.match(/.{2}/g);

        if (aRgbHex) {
            const aRgb = [
                parseInt(aRgbHex[0], 16),
                parseInt(aRgbHex[1], 16),
                parseInt(aRgbHex[2], 16),
            ];

            return aRgb;
        }
    }

    if (hexColor.length === 3) {
        const aRgbHex = hexColor.match(/.{1}/g);

        if (aRgbHex) {
            const aRgb = [
                parseInt(aRgbHex[0] + aRgbHex[0], 16),
                parseInt(aRgbHex[1] + aRgbHex[1], 16),
                parseInt(aRgbHex[2] + aRgbHex[2], 16),
            ];

            return aRgb;
        }
    }
}
