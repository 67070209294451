const idIT = 11043792;
const idEN = 62881510;

const iubendaConfig = {
    siteId: 2726046,
    lang: "it",
    gdprAppliesGlobally: false,
    cookiePolicyInOtherWindow: false,
    consentOnContinuedBrowsing: false,
    perPurposeConsent: true,

    ccpaAcknowledgeOnDisplay: true,
    countryDetection: true,
    enableCcpa: true,
    floatingPreferencesButtonDisplay: "bottom-right",
    invalidateConsentWithoutLog: true,
    whitelabel: false,

    banner: {
        acceptButtonCaptionColor: "#FFFFFF",
        acceptButtonColor: "#006272",
        acceptButtonDisplay: true,
        backgroundColor: "#FFFFFF",
        brandBackgroundColor: "#FFFFFF",
        brandTextColor: "#000000",
        closeButtonDisplay: false,
        customizeButtonCaptionColor: "#4D4D4D",
        customizeButtonColor: "#D5D5D5",
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        position: "float-bottom-center",
        rejectButtonCaptionColor: "#FFFFFF",
        rejectButtonColor: "#006272",
        rejectButtonDisplay: true,
        textColor: "#000000",
    },

    /* eslint-disable */
    callback: {
        onPreferenceExpressedOrNotNeeded: function (preference) {
            dataLayer.push({
                iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
            });
            if (!preference) {
                dataLayer.push({
                    event: "iubenda_preference_not_needed",
                });
            } else if (preference.consent === true) {
                dataLayer.push({
                    event: "iubenda_consent_given",
                });
            } else if (preference.consent === false) {
                dataLayer.push({
                    event: "iubenda_consent_rejected",
                });
            } else if (preference.purposes) {
                for (const purposeId in preference.purposes) {
                    if (preference.purposes[purposeId]) {
                        dataLayer.push({
                            event: "iubenda_consent_given_purpose_" + purposeId,
                        });
                    }
                }
            }
        },
    },
    /* eslint-enable */
};

export const iubendaConfigIT = {
    ...iubendaConfig,
    cookiePolicyId: idIT,
    lang: "it",
};

export const iubendaConfigEN = {
    ...iubendaConfig,
    cookiePolicyId: idEN,
    lang: "en",
};
