import colorText from "../colors";

/**
 * Get section theme
 *
 * @param {string} theme
 *
 * @returns {string}
 *
 */
export function getSectionTheme(theme) {
    let classname = "";

    if (theme && theme !== "") {
        switch (theme) {
            case "primary":
                classname =
                    "bg-primary py-section-inner-spacer 2xl:py-section-inner-spacer-desktop";
                break;
            case "secondary":
                classname =
                    "bg-secondary-light py-section-inner-spacer 2xl:py-section-inner-spacer-desktop";
                break;
            case "white":
                classname = "bg-white";
                break;
            default:
                classname = "bg-white";
                break;
        }

        classname += theme !== "secondary" ? " " + colorText(theme) : "";

        return classname;
    }

    return "bg-white";
}

/**
 * Get classname string by button theme name
 *
 * @param {string} theme - Theme's name
 * @param {boolean} hollow - If theme is hollowed version
 *
 * @returns string
 *
 */
export function getButtonTheme(theme, hollow = false) {
    if (theme && theme !== "") {
        const classList = ["border-1 border-solid"];

        if (hollow === true) {
            switch (theme) {
                case "primary":
                    classList.push("bg-white border-primary text-primary");
                    break;
                case "secondary":
                    classList.push("bg-white border-secondary text-secondary");
                    break;
                case "white":
                    classList.push("bg-transparent border-white");
                    break;
                default:
                    classList.push("bg-white text-primary");
                    break;
            }
        }

        if (hollow === false) {
            switch (theme) {
                case "primary":
                    classList.push("bg-primary border-primary");
                    break;
                case "secondary":
                    classList.push("bg-secondary border-secondary");
                    break;
                case "white":
                    classList.push("bg-white border-white");
                    break;
                default:
                    classList.push("bg-primary border-primary");
                    break;
            }

            classList.push(colorText(theme));
        }

        let className = "";
        classList.forEach((val) => {
            if (val !== "") {
                className += " " + val;
            }
        });

        return className;
    }

    return "bg-white";
}

/**
 * Get classname string by button theme name
 *
 * @param {string} theme - Theme's name
 *
 * @returns string
 *
 */
export function getButtonCircleTheme(theme) {
    if (theme && theme !== "") {
        const classList = [
            "after:bg-transparent after:border-2 after:border-solid",
        ];

        switch (theme) {
            case "primary":
                classList.push("after:border-primary");
                break;
            case "secondary":
                classList.push("after:border-secondary");
                break;
            case "white":
                classList.push("after:border-dark");
                break;
            default:
                classList.push("after:border-primary");
                break;
        }

        let className = "";
        classList.forEach((val) => {
            if (val !== "") {
                className += " " + val;
            }
        });

        return className;
    }

    return "after:bg-transparent after:border-2 after:border-solid after:border-primary";
}
