//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { checkBreakpoint } from "~/helpers";

export default {
    name: "BowHeader",

    data: () => ({
        checkBreakpointUpLg: false,
        heightHeader: 0,
        logoWhite: true,
    }),

    async fetch() {
        // Get menu list from store
        const listStore = this.$store.getters["menu/GET_LIST_20_COUNT"];

        // If there's already saved a menu list in store, skip API call
        if (listStore === 0) {
            await this.$store.dispatch("menu/UPDATE_LIST_20");
        }
    },

    computed: {
        ...mapState({
            bounding: ({ scroll }) => scroll.bounding,
            scroll: ({ scroll }) => scroll.scroll,
            isCoverFirstSection: ({ ui }) => ui.isCoverFirstSection,
            isFiltersOpen: ({ ui }) => ui.isFiltersOpen,
            isNavOpen: ({ ui }) => ui.isNavOpen,
            isPageImmobili: ({ ui }) => ui.isPageImmobili,
            list: ({ menu }) => menu.list20,
            pageHeight: ({ scroll }) => scroll.pageHeight,
        }),

        ...mapGetters([
            "menu/GET_LIST",
            "scroll/getBounding",
            "scroll/GET_SCROLL",
            "ui/GET_IS_COVER_FIRST_SECTION",
            "ui/GET_IS_FILTERS_OPEN",
            "ui/GET_IS_NAV_OPEN",
            "ui/GET_IS_PAGE_IMMOBILI",
            "scroll/getPageHeight",
        ]),

        getBackground() {
            if (this.isFiltersOpen) {
                this.updateLogoColor(true);
                return "text-white";
            }

            if (this.isPageImmobili === true) {
                if (checkBreakpoint.up("xl")) {
                    if (this.isNavOpen === true) {
                        this.updateLogoColor(true);
                        return "text-white";
                    }

                    this.updateLogoColor(true);
                    return "text-white";
                }

                let windowScreenHeight = 0;
                if (typeof window !== "undefined") {
                    windowScreenHeight = window.screen.height;
                }

                const endMap = windowScreenHeight - 3 * 16 + 45 / 2;

                if (this.scroll.current.y > endMap) {
                    if (this.isNavOpen === true) {
                        this.updateLogoColor(true);
                        return "text-white";
                    }

                    this.updateLogoColor(false);
                    return "bg-white text-primary";
                }

                if (this.isNavOpen === true) {
                    this.updateLogoColor(true);
                    return "text-white";
                }

                this.updateLogoColor(true);
                return "text-white";
            }

            if (this.isCoverFirstSection && this.isCoverFirstSection === true) {
                if (
                    this.heightHeader > 0 &&
                    this.scroll.current.y > this.heightHeader
                ) {
                    if (this.isNavOpen === true) {
                        this.updateLogoColor(true);
                        return "text-white";
                    }

                    this.updateLogoColor(false);
                    return "bg-white text-primary";
                }

                if (this.isNavOpen === true) {
                    this.updateLogoColor(true);
                    return "text-white";
                }

                this.updateLogoColor(true);
                return "text-white";
            }

            if (this.isNavOpen === true) {
                this.updateLogoColor(true);
                return "text-white";
            }

            this.updateLogoColor(false);
            return "bg-white text-primary";
        },
    },

    mounted() {
        this.checkBreakpointUpLg = checkBreakpoint.up("lg");

        this.heightHeader = document
            .querySelector(".o-header__wrapper")
            .getBoundingClientRect().height;
    },

    destroyed() {
        this.$store.dispatch("ui/UPDATE_IS_NAV_OPEN", false);
        document.querySelector("body").classList.remove("overflow-y-hidden");
    },

    methods: {
        ...mapActions(["ui/UPDATE_IS_NAV_OPEN", "menu/UPDATE_LIST_20"]),

        updateLogoColor(white = true) {
            if (white === true) {
                this.logoWhite = true;
                return;
            }

            this.logoWhite = false;
        },
    },
};
