var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._g(_vm._b({tag:"component",class:[
        'a-menu-link',
        'cursor-pointer',
        'font-bold text-white text-2xl',
        'lg:text-3xl 2xl:text-5xl',
        _vm.isActive === true
            ? 'translate-x-[5%] duration-500 transition-all'
            : _vm.isDesktop
            ? 'hover:translate-x-[5%] duration-500 transition-all'
            : '' ]},'component',_vm.$attrs,false),_vm.listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }