export function move(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
}

export function splitArrayIntoGroups(arr, numGroups) {
    const perGroup = Math.ceil(arr.length / numGroups);

    const newList = new Array(numGroups)
        .fill("")
        .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));

    const list = [];

    newList.forEach((item) => {
        if (item.length === 0) {
            return;
        }

        list.push(item);
    });

    return list;
}

export function getRandomElements(arr, num = 1) {
    const res = [];

    for (let i = 0; i < num; ) {
        const random = Math.floor(Math.random() * arr.length);

        if (res.includes(arr[random])) {
            continue;
        }

        res.push(arr[random]);

        i++;
    }

    return res;
}
