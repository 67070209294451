import layerEvents from "../../lib/layerEvents";
import mixin from "./layerMixin";

export default {
    name: "RasterLayer",
    mixins: [mixin],

    created() {
        this.$_deferredMount();
    },

    methods: {
        $_deferredMount() {
            const source = {
                type: "raster",
                ...this.source,
            };

            this.map.on("dataloading", this.$_watchSourceLoading);
            try {
                this.map.addSource(this.sourceId, source);
            } catch (err) {
                if (this.replaceSource) {
                    this.map.removeSource(this.sourceId);
                    this.map.addSource(this.sourceId, source);
                }
            }
            this.$_addLayer();
            this.$_bindLayerEvents(layerEvents);
            this.map.off("dataloading", this.$_watchSourceLoading);
            this.initial = false;
        },

        $_addLayer() {
            const existed = this.map.getLayer(this.layerId);
            if (existed) {
                if (this.replace) {
                    this.map.removeLayer(this.layerId);
                } else {
                    this.$_emitEvent("layer-exists", { layerId: this.layerId });
                    return existed;
                }
            }
            const layer = {
                id: this.layerId,
                type: "raster",
                source: this.sourceId,
                ...this.layer,
            };

            this.map.addLayer(layer, this.before);
            this.$_emitEvent("added", { layerId: this.layerId });
        },
    },
};
