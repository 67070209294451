import { render, staticRenderFns } from "./BowFooter.vue?vue&type=template&id=ec77b782&"
import script from "./BowFooter.vue?vue&type=script&lang=js&"
export * from "./BowFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowText: require('/workspace/components/atoms/BowText/BowText.vue').default,BowLogo: require('/workspace/components/atoms/BowLogo/BowLogo.vue').default})
