//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "BowMenu",

    props: {
        htmlTag: {
            default: null,
            type: String,
        },

        isNavOpen: {
            default: false,
            type: Boolean,
        },
    },

    data: () => ({}),

    async fetch() {
        // Get menu list from store
        const listStore = this.$store.getters["menu/GET_LIST_COUNT"];

        // If there's already saved a menu list in store, skip API call
        if (listStore === 0) {
            await this.$store.dispatch("menu/UPDATE_LIST");
        }
    },

    computed: {
        ...mapState({
            list: ({ menu }) => menu.list,
        }),
        ...mapGetters(["menu/GET_LIST"]),
    },

    watch: {
        isNavOpen(value, oldValue) {
            if (value === true) {
                const children = [...this.$children];

                const tl = this.$anime.timeline({
                    duration: 500,
                    easing: "linear",
                    complete: (anim) => {
                        children.forEach((item, idx) => {
                            item.$el.removeAttribute("style");
                        });
                    },
                });

                this.$children.forEach((item, idx) => {
                    tl.add(
                        {
                            targets: item.$el,
                            translateX: ["-5%", 0],
                            opacity: 1,
                        },
                        "-=250"
                    );
                });
            } else {
                this.$children.forEach((item) => {
                    item.$el.style.opacity = 0;
                    item.$el.style.transform = "translateX(-5%)";
                });
            }
        },
    },

    // mounted() {
    //   console.log(this.$children);
    //   this.$children.forEach((item) => {
    //     item.$el.style.opacity = 0;
    //     item.$el.style.transform = 'translateX(-5%)';
    //   });
    // },

    methods: {
        ...mapActions(["menu/UPDATE_LIST"]),
    },
};
