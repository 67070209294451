import getComponentName from './getComponentName';
import { servizi } from '~/config';

class BuildData {
    /**
     * Build sections array from WordPress Post Object
     *
     * @param { Object } post - WordPress Post Object
     * @param { String } type - Post type
     *
     * @returns { Object }
     *
     */
    constructor(post, type = 'page', options = {}) {
        this.post = post;
        this.type = type || 'page';

        this.options = options;

        this.lang = null;
        if (this.options.lang === 'it' || this.options.lang === 'it-IT') {
            this.lang = 'it-IT';
        }

        if (this.options.lang === 'en') {
            this.lang = 'en-GB';
        }

        this.debug = false;

        this.tipologiaImmobile = null;
        this.nomeImmobile = null;
        this.featuredImage = null;
        this.cittaImmobile = null;
        this.services = null;

        this.isFeatured = false;

        this.seo = {
            title: null,
            description: null,
            breadcrumb_title: null,
            is_robots_noindex: null,
            is_robots_nofollow: false,
            is_robots_noarchive: null,
            is_robots_noimageindex: null,
            is_robots_nosnippet: null,
            twitter_title: null,
            twitter_image: null,
            twitter_description: null,
            twitter_image_id: null,
            twitter_image_source: null,
            open_graph_title: null,
            open_graph_description: null,
            open_graph_image: null,
            open_graph_image_id: null,
            open_graph_image_source: null,
            open_graph_image_meta: null,
        };

        this.prezzo = {
            value: null,
            label: null,
        };

        this.coordinates = {
            lat: null,
            lng: null,
        };

        this.isCoverFirstSection = false;

        this.images = {};
        this.sections = [];
    }

    /**
     * Build data object for current page
     *
     * @returns { Object }
     *
     */
    build() {
        if (!this.post || !this.type) {
            return {};
        }

        if (this.debug === true) {
            console.log(this.post);
        }

        if (this.post.yoastIndexable) {
            const yoast = this.post.yoastIndexable;

            this.seo = {
                title: yoast.title && yoast.title !== '' ? yoast.title : null,
                description:
                    yoast.description && yoast.description !== ''
                        ? yoast.description
                        : null,
                breadcrumb_title:
                    yoast.breadcrumb_title && yoast.breadcrumb_title !== ''
                        ? yoast.breadcrumb_title
                        : null,
                is_robots_noindex:
                    yoast.is_robots_noindex !== null
                        ? yoast.is_robots_noindex
                        : null,
                is_robots_nofollow:
                    yoast.is_robots_nofollow !== null
                        ? yoast.is_robots_nofollow
                        : null,
                is_robots_noarchive:
                    yoast.is_robots_noarchive !== null
                        ? yoast.is_robots_noarchive
                        : null,
                is_robots_noimageindex:
                    yoast.is_robots_noimageindex !== null
                        ? yoast.is_robots_noimageindex
                        : null,
                is_robots_nosnippet:
                    yoast.is_robots_nosnippet !== null
                        ? yoast.is_robots_nosnippet
                        : null,
                twitter_title:
                    yoast.twitter_title && yoast.twitter_title !== ''
                        ? yoast.twitter_title
                        : null,
                twitter_image:
                    yoast.twitter_image && yoast.twitter_image !== ''
                        ? yoast.twitter_image
                        : null,
                twitter_description:
                    yoast.twitter_description &&
                    yoast.twitter_description !== ''
                        ? yoast.twitter_description
                        : null,
                twitter_image_id:
                    yoast.twitter_image_id && yoast.twitter_image_id !== ''
                        ? yoast.twitter_image_id
                        : null,
                twitter_image_source:
                    yoast.twitter_image_source &&
                    yoast.twitter_image_source !== ''
                        ? yoast.twitter_image_source
                        : null,
                open_graph_title:
                    yoast.open_graph_title && yoast.open_graph_title !== ''
                        ? yoast.open_graph_title
                        : null,
                open_graph_description:
                    yoast.open_graph_description &&
                    yoast.open_graph_description !== ''
                        ? yoast.open_graph_description
                        : null,
                open_graph_image:
                    yoast.open_graph_image && yoast.open_graph_image !== ''
                        ? yoast.open_graph_image
                        : null,
                open_graph_image_id:
                    yoast.open_graph_image_id &&
                    yoast.open_graph_image_id !== ''
                        ? yoast.open_graph_image_id
                        : null,
                open_graph_image_source:
                    yoast.open_graph_image_source &&
                    yoast.open_graph_image_source !== ''
                        ? yoast.open_graph_image_source
                        : null,
                open_graph_image_meta:
                    yoast.open_graph_image_meta &&
                    yoast.open_graph_image_meta !== ''
                        ? yoast.open_graph_image_meta
                        : null,
            };
        }

        if (this.type === 'immobile') {
            if (this.post.customFields) {
                const tipologiaImmobileCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_tipologia_immobile';
                    }
                );

                if (tipologiaImmobileCf) {
                    const tipologiaImmobile = tipologiaImmobileCf[0];

                    if (tipologiaImmobile) {
                        this.tipologiaImmobile = 'Appartamento';

                        if (tipologiaImmobile.cfv_meta_value === 'villa') {
                            this.tipologiaImmobile = 'Villa';
                        }

                        if (tipologiaImmobile.cfv_meta_value === 'casa') {
                            this.tipologiaImmobile = 'Casa';
                        }

                        // tipologiaImmobile.cfv_meta_value === 'villa'
                        //     ? 'Villa'
                        //     : 'Appartamento';
                    }
                }

                const nomeImmobileCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_nome_immobile';
                    }
                );

                if (nomeImmobileCf) {
                    const nomeImmobile = nomeImmobileCf[0];

                    if (nomeImmobile) {
                        this.nomeImmobile = nomeImmobile.cfv_meta_value;
                    }
                }

                const isFeaturedCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_in_evidenza';
                    }
                );

                if (isFeaturedCf) {
                    const isFeatured = isFeaturedCf[0];

                    if (isFeatured) {
                        this.isFeatured = isFeatured.cfv_meta_value === '1';
                    }
                }

                const latitudineCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_latitudine_mappa';
                    }
                );

                if (latitudineCf) {
                    const latitudine = latitudineCf[0];

                    if (latitudine && latitudine.cfv_meta_value !== '') {
                        this.coordinates.lat = Number(
                            latitudine.cfv_meta_value
                        );
                    }
                }

                const longitudineCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_longitudine_mappa';
                    }
                );

                if (longitudineCf) {
                    const longitudine = longitudineCf[0];

                    if (longitudine && longitudine.cfv_meta_value !== '') {
                        this.coordinates.lng = Number(
                            longitudine.cfv_meta_value
                        );
                    }
                }

                const prezzoCf = this.post.customFields.filter((el, idx) => {
                    return el.cf_meta_key === '_prezzo';
                });

                if (prezzoCf) {
                    const prezzo = prezzoCf[0];

                    if (prezzo) {
                        this.prezzo.value = prezzo.cfv_meta_value;
                    }
                }

                const prezzoLabelCf = this.post.customFields.filter(
                    (el, idx) => {
                        return el.cf_meta_key === '_tipologia_prezzo';
                    }
                );

                if (prezzoLabelCf) {
                    const prezzoLabel = prezzoLabelCf[0];

                    if (prezzoLabel) {
                        this.prezzo.label = prezzoLabel.cfv_meta_value;
                    }
                }
            }
        }

        if (this.post.images && this.post.images.length > 0) {
            this.post.images.forEach((img) => {
                let resizes = {};

                if (img.resizedFeaturedImages) {
                    resizes = {
                        ...img.resizedFeaturedImages,
                    };
                }

                let alt =
                    img.post_title && img.post_title !== ''
                        ? img.post_title
                        : null;
                if (img.postMetas && img.postMetas.length > 0) {
                    const altFiltered = img.postMetas.filter(
                        it => it.meta_key === '_wp_attachment_image_alt'
                    );
                    if (
                        altFiltered &&
                        altFiltered.length === 1 &&
                        altFiltered[0]
                    ) {
                        if (
                            altFiltered[0].meta_value &&
                            altFiltered[0].meta_value !== ''
                        ) {
                            alt = altFiltered[0].meta_value;
                        }
                    }
                }

                this.images[img.id] = {
                    alt,
                    id: img.id,
                    image: {
                        height: 1080,
                        provider: 'wordpress',
                        sizes: { ...resizes },
                        url: img.guid,
                        width: 1920,
                    },
                };
            });
        }

        const sectionsList = [];
        if (this.post.contentBlocks) {
            const rawSections = this.post.contentBlocks;
            rawSections.forEach((_) => {
                sectionsList.push({
                    id: _.id,
                    content:
                        _.source_json !== '' ? JSON.parse(_.source_json) : null,
                });
            });
        }

        if (this.debug === true) {
            console.log(sectionsList);
        }

        if (sectionsList.length > 0) {
            sectionsList.forEach((section) => {
                if (
                    !section ||
                    section === null ||
                    typeof section === 'undefined'
                ) {
                    return;
                }

                if (
                    !section.content ||
                    section.content === null ||
                    typeof section.content === 'undefined'
                ) {
                    return;
                }

                if (
                    !section.content.status_block ||
                    (section.content.status_block &&
                        section.content.status_block !== 'on')
                ) {
                    return;
                }

                const id = section.id;
                const idBlock =
                    section.content.id_block && section.content.id_block !== ''
                        ? section.content.id_block
                        : null;

                const component = getComponentName(id);

                const isActivity =
                    id === 'bow-lh-activities-list-media-dx' ||
                    id === 'bow-lh-activities-list-media-sx';

                const contactList = [];

                if (
                    section.content[`contact_list-${id}`] &&
                    section.content[`contact_list-${id}`].rows &&
                    section.content[`contact_list-${id}`].rows.length > 0
                ) {
                    [...section.content[`contact_list-${id}`].rows].forEach(
                        (item, idx) => {
                            if (item) {
                                contactList[idx] = {
                                    name: item.nome_cognome,
                                    role: item.ruolo,
                                    phone: item.telefono,
                                    phoneValidated: String(item.telefono)
                                        .trim()
                                        .replace(/^\(\+[0-9]+\)/, '')
                                        .replace(' ', '')
                                        .trim(),
                                };
                            }
                        }
                    );
                }

                const contentList = [];

                if (
                    section.content[`content_list-${id}`] &&
                    section.content[`content_list-${id}`].rows &&
                    section.content[`content_list-${id}`].rows.length > 0
                ) {
                    [...section.content[`content_list-${id}`].rows].forEach(
                        (item, idx) => {
                            if (item) {
                                let mediaContent = null;

                                if (
                                    item.image &&
                                    typeof item.image === 'number'
                                ) {
                                    const imageID = item.image;
                                    const imageData = this.images[imageID];

                                    if (imageData) {
                                        mediaContent = {
                                            ...imageData,
                                            type: 'image',
                                        };
                                    }
                                }

                                contentList[idx] = {
                                    title: item.title,
                                    text: item.text,
                                    cta:
                                        item.link &&
                                        item.link !== '' &&
                                        item.text_link &&
                                        item.text_link !== ''
                                            ? [
                                                {
                                                    link: item.link,
                                                    title_link:
                                                          item.title_link
                                                              ? item.title_link
                                                              : item.text_link,
                                                    text_link: item.text_link,
                                                    type_button:
                                                          item.type_button
                                                              ? item.type_button
                                                              : 'Link interno',
                                                },
                                            ]
                                            : [],
                                    image: mediaContent,
                                };
                            }
                        }
                    );
                }

                const cta = [];
                if (
                    section.content[`call_to_action-${id}`] &&
                    section.content[`call_to_action-${id}`].rows &&
                    section.content[`call_to_action-${id}`].rows.length > 0
                ) {
                    [...section.content[`call_to_action-${id}`].rows].forEach(
                        (item, idx) => {
                            if (
                                item &&
                                item.link &&
                                item.link !== '' &&
                                item.text_link &&
                                item.text_link !== ''
                            ) {
                                cta[idx] = {
                                    link: item.link,
                                    title_link: item.title_link
                                        ? item.title_link
                                        : item.text_link,
                                    text_link: item.text_link,
                                    type_button: item.type_button
                                        ? item.type_button
                                        : 'Link interno',
                                };
                            }
                        }
                    );
                }

                const ctaCol2 = [];
                if (
                    section.content[`call_to_action-col_2-${id}`] &&
                    section.content[`call_to_action-col_2-${id}`].rows &&
                    section.content[`call_to_action-col_2-${id}`].rows.length >
                        0
                ) {
                    [
                        ...section.content[`call_to_action-col_2-${id}`].rows,
                    ].forEach((item, idx) => {
                        if (
                            item &&
                            item.link &&
                            item.link !== '' &&
                            item.text_link &&
                            item.text_link !== ''
                        ) {
                            ctaCol2[idx] = {
                                link: item.link,
                                title_link: item.title_link
                                    ? item.title_link
                                    : item.text_link,
                                text_link: item.text_link,
                                type_button: item.type_button
                                    ? item.type_button
                                    : 'Link interno',
                            };
                        }
                    });
                }

                const media = [];
                if (
                    section.content[`media_list-${id}`] &&
                    section.content[`media_list-${id}`].rows &&
                    section.content[`media_list-${id}`].rows.length > 0
                ) {
                    [...section.content[`media_list-${id}`].rows].forEach(
                        (item, idx) => {
                            if (item.video && typeof item.video === 'string') {
                                media[idx] = {
                                    type: 'video',
                                    provider: 'wordpress',
                                    url: item.video,
                                };

                                return;
                            }

                            if (item.image && typeof item.image === 'number') {
                                const imageID = item.image;
                                const imageData = this.images[imageID];

                                if (imageData) {
                                    media[idx] = {
                                        ...imageData,
                                        image: {
                                            ...imageData.image,
                                            alt: imageData.alt,
                                        },
                                        type: 'image',
                                    };
                                }
                            }
                        }
                    );
                }

                let mediaToggle = false;
                if (
                    section.content[`media_toggle_list-${id}`] &&
                    section.content[`media_toggle_list-${id}`].rows &&
                    section.content[`media_toggle_list-${id}`].rows.length > 0
                ) {
                    mediaToggle = [];

                    [
                        ...section.content[`media_toggle_list-${id}`].rows,
                    ].forEach((item, idx) => {
                        if (item.image && typeof item.image === 'number') {
                            const imageID = item.image;
                            const imageData = this.images[imageID];

                            if (imageData) {
                                mediaToggle[idx] = {
                                    ...imageData,
                                    image: {
                                        ...imageData.image,
                                        alt: imageData.alt,
                                    },
                                    type: 'image',
                                    title:
                                        item.value_heading &&
                                        item.value_heading !== ''
                                            ? item.value_heading
                                            : '',
                                };
                            }
                        }
                    });
                }

                const posts = [];
                if (
                    section.content[`posts-${id}`] &&
                    section.content[`posts-${id}`].rows &&
                    section.content[`posts-${id}`].rows.length > 0
                ) {
                    [...section.content[`posts-${id}`].rows].forEach(
                        (item, idx) => {
                            if (item && item.single_post) {
                                posts[idx] = { ...item.single_post };
                            }
                        }
                    );
                }

                const textColumns =
                    section.content.text_columns &&
                    section.content.text_columns !== ''
                        ? parseInt(section.content.text_columns, 10)
                        : null;

                const text =
                    section.content.text && section.content.text !== ''
                        ? section.content.text
                        : null;

                const textCol2 =
                    section.content.text_col_2 &&
                    section.content.text_col_2 !== ''
                        ? section.content.text_col_2
                        : null;

                const titles = [];
                if (
                    section.content[`headings-${id}`] &&
                    section.content[`headings-${id}`].rows &&
                    section.content[`headings-${id}`].rows.length > 0
                ) {
                    [...section.content[`headings-${id}`].rows].forEach(
                        (item, idx) => {
                            if (
                                item.value_heading !== null &&
                                item.value_heading !== ''
                            ) {
                                titles.push(item);
                            }
                        }
                    );
                }

                const titlesCol2 = [];
                if (
                    section.content[`headings-col_2-${id}`] &&
                    section.content[`headings-col_2-${id}`].rows &&
                    section.content[`headings-col_2-${id}`].rows.length > 0
                ) {
                    [...section.content[`headings-col_2-${id}`].rows].forEach(
                        (item, idx) => {
                            if (
                                item.value_heading !== null &&
                                item.value_heading !== ''
                            ) {
                                titlesCol2.push(item);
                            }
                        }
                    );
                }

                const spacer =
                    section.content.spacing_block &&
                    section.content.spacing_block !== ''
                        ? section.content.spacing_block
                        : 'base';

                const theme =
                    section.content.background_block &&
                    section.content.background_block !== ''
                        ? section.content.background_block
                        : null;

                let post = null;
                if (section.content.post && section.content.post.id !== 0) {
                    post = section.content.post;
                }

                let postImmobile = null;
                if (
                    section.content.post_immobile &&
                    section.content.post_immobile.id !== 0
                ) {
                    postImmobile = section.content.post_immobile;
                }

                let postPage = null;
                if (
                    section.content.post_page &&
                    section.content.post_page.id !== 0
                ) {
                    postPage = section.content.post_page;
                }

                const coordinates = {
                    lat: null,
                    lng: null,
                };

                if (
                    section.content.latitudine &&
                    section.content.latitudine !== ''
                ) {
                    coordinates.lat = section.content.latitudine;
                }

                if (
                    section.content.longitudine &&
                    section.content.longitudine !== ''
                ) {
                    coordinates.lng = section.content.longitudine;
                }

                let indirizzo = null;

                if (
                    section.content.indirizzo &&
                    section.content.indirizzo !== ''
                ) {
                    indirizzo = section.content.indirizzo;
                }

                this.sections.push({
                    component,
                    contactList,
                    contentList,
                    coordinates,
                    cta,
                    cta_col_2: ctaCol2,
                    id: idBlock,
                    isActivity,
                    indirizzo,
                    media,
                    mediaToggle,
                    post,
                    postImmobile,
                    postPage,
                    posts,
                    text,
                    textColumns,
                    text_col_2: textCol2,
                    title: titles,
                    title_col_2: titlesCol2,
                    spacer,
                    theme,
                });
            });
        }

        if (this.post.featuredImage) {
            this.featuredImage = {
                id: this.post.featuredImage.id,
                alt: this.post.featuredImage.post_title || 'Liberty Homes',
                type: 'image',
                image: {
                    height: 1080,
                    provider: 'wordpress',
                    url: this.post.featuredImage.guid,
                    width: 1920,
                },
            };
        }

        if (this.sections.length > 0) {
            const component = this.sections[0].component;

            switch (component) {
            case 'BowCover':
            case 'BowCoverImmobile':
            case 'BowCoverPreview':
            case 'BowCoverSlider':
                this.isCoverFirstSection = true;
                break;

            default:
                this.isCoverFirstSection = false;
                break;
            }
        }

        if (this.post.customFields && this.type === 'immobile') {
            this.services = this.buildServices();
            this.cittaImmobile = this.services.citta;
        }

        return {
            cittaImmobile: this.cittaImmobile,
            coordinates: this.coordinates,
            featuredImage: this.featuredImage,
            id: this.post.id,
            images: this.images,
            isCoverFirstSection: this.isCoverFirstSection,
            isFeatured: this.isFeatured,
            nomeImmobile: this.nomeImmobile,
            prezzo: this.prezzo,
            sections: this.sections,
            seo: this.seo,
            services: this.services,
            slug: this.post.post_name,
            tipologiaImmobile: this.tipologiaImmobile,
            title: this.post.post_title,
        };
    }

    /**
     * Get all services data for a single immobile
     *
     * @returns { Object } - The list of all services
     *
     */
    buildServices() {
        if (!this.post.customFields || this.type !== 'immobile') {
            return null;
        }

        const serviziList = {};

        const defaultServizi = servizi;

        if (defaultServizi && defaultServizi.length > 0) {
            defaultServizi.forEach((item, index) => {
                const getCf = this.post.customFields.filter((el, idx) => {
                    return el.cf_meta_key === item.cfKey;
                });

                if (!getCf) {
                    return;
                }

                const firstItem = getCf[0];

                if (!firstItem) {
                    return;
                }

                const cfValue = firstItem.cfv_meta_value;

                let value = cfValue && cfValue !== '' ? cfValue : null;
                if (typeof value === 'number') {
                    value = Number(value).toLocaleString(this.lang, {
                        maximumSignificantDigits: 2,
                    });
                }

                serviziList[item.key] = {
                    cfKey: item.cfKey,
                    icon: item.icon,
                    label: item.label,
                    value,
                };
            });
        }

        return serviziList;
    }
}

export default BuildData;
