var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.type === 'link'
            ? 'NuxtLink'
            : _vm.type === 'link-ext' || _vm.type === 'link-classic'
            ? 'a'
            : 'button',{tag:"component",class:['a-button', 'cursor-[pointer_!important]', _vm.generateClassname],attrs:{"href":_vm.type === 'link-ext' || _vm.type === 'link-classic' ? _vm.href : null,"rel":_vm.type === 'link-ext' ? 'noopener' : null,"target":_vm.type === 'link-ext' ? '_blank' : null,"title":_vm.title,"to":_vm.type === 'link' ? _vm.localePath(_vm.href) : null,"type":_vm.type !== 'link' &&
        _vm.type !== 'link-ext' &&
        _vm.type !== 'link-classic' &&
        (_vm.type === 'button' || _vm.type === 'submit')
            ? _vm.type
            : null},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon)?_c('BowIcons',{staticClass:"mr-3",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),(_vm.hasInnerSpan === true)?_c('span',{class:['text-center leading-6', _vm.generateInnerSpanClassname]},[_vm._t("default")],2):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }