var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-locomotive-scroll"},[(_vm.sections && _vm.sections.length > 0)?_c('div',{directives:[{name:"locomotive",rawName:"v-locomotive",value:({ options: _vm.options }),expression:"{ options }"}],staticClass:"b-scroll__wrapper relative js-locomotive",attrs:{"data-scroll-container":""}},[_vm._l((_vm.sections),function(section,index){return _c('BowSection',{key:("section-" + index),class:[
                section.component === 'BowCTABlockSx' ||
                section.component === 'BowCTABlockDx'
                    ? [
                          _vm.devicePixelRatio.current === 1
                              ? 'is-cta-block'
                              : '',
                          _vm.devicePixelRatio.current > 1
                              ? 'is-cta-block-zoom'
                              : '' ]
                    : '' ],attrs:{"id":section.id,"spacer":section.spacer,"theme":section.component !== 'BowCoverPreview'
                    ? section.theme
                    : null,"data-component":section.component}},[_c(section.component,{tag:"component",attrs:{"contactList":section.contactList,"contentList":section.contentList,"coordinates":section.coordinates,"cta":section.cta,"ctaCol2":section.cta_col_2 ? section.cta_col_2 : null,"isActivity":section.isActivity !== null ? section.isActivity : null,"isImmobile":section.postImmobile ? true : false,"indirizzo":section.indirizzo,"media":section.media,"mediaToggle":section.mediaToggle,"post":section.postImmobile
                        ? section.postImmobile
                        : section.postPage
                        ? section.postPage
                        : section.post
                        ? section.post
                        : null,"reviews":section.posts ? section.posts : null,"text":section.text,"textCol2":section.text_col_2 ? section.text_col_2 : null,"textColumns":section.textColumns ? section.textColumns : null,"theme":section.component === 'BowCoverPreview'
                        ? section.theme
                        : null,"title":section.title,"titleCol2":section.title_col_2 ? section.title_col_2 : null}})],1)}),_vm._v(" "),(_vm.checkBreakpointDownXl)?_c('BowFooter',{attrs:{"data-scroll-section":""}}):_vm._e()],2):_c('div',{directives:[{name:"locomotive",rawName:"v-locomotive",value:({ options: _vm.options }),expression:"{ options }"}],staticClass:"b-scroll__wrapper b-scroll-cols__wrapper js-locomotive",attrs:{"data-scroll-container":""}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }