//
//
//
//
//
//

export default {
    name: "NuxtDefault",

    middleware: "mapbox",

    data: () => ({
        magicMouse: null,
    }),

    mounted() {
        this.magicMouse = this.$magicMouse({
            cursorOuter: "circle-basic",
            defaultCursor: false,
            hoverEffect: "circle-move",
            hoverItemMove: false,
            outerHeight: 30,
            outerWidth: 30,
        });
    },
};
