import hex2Rgb from "./hex2Rgb";

/**
 * Calculate
 *
 * @param {mixed} color
 *
 * @returns {number}
 *
 */
export default function (color) {
    let backgroundColor = color;

    const hex = color.match(/^#[0-9|A-F|a-f]+$/);

    if (hex) {
        backgroundColor = hex2Rgb(String(color).replace("#", ""));
    } else {
        const rgb = color.match(/$rgb\([0-9]+,[\s|][0-9]+,[\s|][0-9]+\)/);

        if (rgb) {
            const rgbPreArr = String(color)
                .replace("rgb(", "")
                .replace(")", "");
            backgroundColor = rgbPreArr.split(",");
        }
    }

    const luminance =
        (0.299 * backgroundColor[0] +
            0.587 * backgroundColor[1] +
            0.114 * backgroundColor[2]) /
        255;

    return luminance;
}
