//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { checkBreakpoint } from "~/helpers";

export default {
    name: "BowFooter",

    props: {
        isDesktop: {
            default: false,
            type: Boolean,
        },
        isImmobili: {
            default: false,
            type: Boolean,
        },
    },

    data: () => ({
        checkBreakpointUpXl: false,
        heightFooter: 0,
    }),

    // async fetch() {
    //   if ( this.businessData === null || this.social === null ) {
    //     await this.$store.dispatch('options/UPDATE_OPTIONS');
    //   }
    // },

    computed: {
        ...mapState({
            bounding: ({ scroll }) => scroll.bounding,
            scroll: ({ scroll }) => scroll.scroll,
            pageHeight: ({ scroll }) => scroll.pageHeight,
            isCoverFirstSection: ({ ui }) => ui.isCoverFirstSection,
            isNavOpen: ({ ui }) => ui.isNavOpen,
            isPageImmobili: ({ ui }) => ui.isPageImmobili,

            businessData: ({ options }) => options.businessData,
        }),

        ...mapGetters([
            "scroll/GET_SCROLL",
            "scroll/getBounding",
            "scroll/getPageHeight",
            "ui/GET_IS_NAV_OPEN",
            "ui/GET_IS_PAGE_IMMOBILI",
            "ui/GET_IS_COVER_FIRST_SECTION",
            "options/GET_BUSINESS_DATA",
        ]),

        getBackground() {
            if (this.isPageImmobili === true) {
                // if ( this.isNavOpen === true ) {
                //   return 'bg-transparent text-white';
                // }

                // return 'bg-transparent text-white';

                if (checkBreakpoint.up("xl")) {
                    if (this.isNavOpen === true) {
                        return "bg-transparent text-white";
                    }

                    return "bg-transparent text-white";
                }
            }

            if (this.isCoverFirstSection && this.isCoverFirstSection === true) {
                if (this.scroll.current.y > this.heightFooter) {
                    if (this.isNavOpen === true) {
                        return "bg-transparent text-white";
                    }

                    return "bg-white text-dark";
                }

                if (this.isNavOpen === true) {
                    return "bg-transparent text-white";
                }

                return "bg-transparent text-white";
            }

            if (this.isNavOpen === true) {
                return "bg-transparent text-white";
            }

            return "bg-white text-dark";
        },
    },

    mounted() {
        this.checkBreakpointUpXl = checkBreakpoint.up("xl");

        this.heightFooter = this.$el.getBoundingClientRect().height;
    },
};
