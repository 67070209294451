export default {
    className: {
        default: null,
        type: String,
    },

    contactList: {
        default: null,
        type: Array,
    },

    contentList: {
        default: null,
        type: Array,
    },

    coordinates: {
        default: () => ({
            lat: null,
            lng: null,
        }),
        type: Object,
    },

    cta: {
        default: null,
        type: Array,
    },

    ctaCol2: {
        default: null,
        type: Array,
    },

    htmlTag: {
        default: null,
        type: String,
    },

    isActivity: {
        default: false,
        type: Boolean,
    },

    isImmobile: {
        default: true,
        type: Boolean,
    },

    indirizzo: {
        default: null,
        type: String,
    },

    media: {
        default: null,
        type: Array,
    },

    mediaToggle: {
        default: false,
        type: [Boolean, Array],
    },

    post: {
        default: null,
        type: Object,
    },

    reviews: {
        default: null,
        type: Array,
    },

    text: {
        default: null,
        type: String,
    },

    textCol2: {
        default: null,
        type: String,
    },

    textColumns: {
        default: 1,
        type: Number,
    },

    theme: {
        default: null,
        type: String,
    },

    title: {
        default: null,
        type: Array,
    },

    titleCol2: {
        default: null,
        type: Array,
    },
};
