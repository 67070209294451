var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{tag:"component",class:[
        'a-menu-toggle group',
        'bg-transparent cursor-pointer flex h-10 items-center justify-start',
        _vm.white ? 'text-white' : 'text-primary' ],on:{"click":_vm.handleMenuToggle}},[_c('div',{class:[
            'flex flex-col h-full items-end justify-center',
            _vm.state === 'open' ? 'duration-300 hover:scale-75' : '' ]},[_c('div',{class:_vm.getCommonClasses.concat( ['scale-75 origin-right duration-300'],
                ['group-hover:scale-100'],
                [_vm.state === 'open'
                    ? 'rotate-45 scale-100 translate-x-[-3px] translate-y-[21px]'
                    : ''] )}),_vm._v(" "),_c('div',{class:_vm.getCommonClasses.concat( ['mt-3 origin-left duration-300'],
                [_vm.state === 'open'
                    ? '-rotate-45 translate-x-[8px] translate-y-[8px]'
                    : ''] )})]),_vm._v(" "),_c('div',{staticClass:"hidden lg:block lg:h-full lg:min-w-16 lg:ml-4 lg:relative lg:w-16"},[_c('span',{class:[
                'lg:absolute lg:block lg:duration-300 lg:font-bold lg:left-0 lg:top-1/2 lg:transition lg:uppercase lg:w-full',
                _vm.state === 'open'
                    ? 'lg:opacity-0 lg:translate-y-full'
                    : 'lg:opacity-1 lg:-translate-y-1/2' ]},[_vm._v("\n            "+_vm._s(_vm.$t("Menu"))+"\n        ")]),_vm._v(" "),_c('span',{class:[
                'lg:absolute lg:block lg:duration-300 lg:font-bold lg:left-0 lg:top-1/2 lg:transition lg:uppercase lg:w-full',
                _vm.state === 'open'
                    ? 'lg:opacity-1 lg:-translate-y-1/2'
                    : 'lg:opacity-0 lg:-translate-y-full' ]},[_vm._v("\n            "+_vm._s(_vm.$t("Chiudi"))+"\n        ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }