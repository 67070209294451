export const state = () => ({
    data: null,
});

export const getters = {
    getData(state) {
        return state.data;
    },
};

export const mutations = {
    setData(state, value) {
        state.data = value;
    },
};

export const actions = {
    updateData({ commit }, data) {
        commit("setData", data);
    },
};
