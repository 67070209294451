export const state = () => ({
    data: null,
    info: {
        immagineImmobile: null,
        nomeImmobile: null,
        tipologiaImmobile: null,
        servizi: null,
        prezzo: {
            value: null,
            label: null,
        },
    },
});

export const getters = {
    getData(state) {
        return state.data;
    },
    getInfo({
        immagineImmobile,
        nomeImmobile,
        servizi,
        tipologiaImmobile,
        prezzo,
    }) {
        return {
            immagineImmobile,
            nomeImmobile,
            tipologiaImmobile,
            servizi,
            prezzo,
        };
    },
};

export const mutations = {
    setData(state, value) {
        state.data = value;
    },
    setInfo(state, value) {
        state.info = value;
    },
};

export const actions = {
    updateData({ commit }, data) {
        commit('setData', data);
    },
    updateInfo({ commit }, data) {
        commit('setInfo', data);
    },
};
