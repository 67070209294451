import { BuildData } from '~/helpers';

export default async function (context, COL_COUNT = 3) {
    const { route, $axios, $getIDBySlug, i18n, error, store } = context;

    try {
        const optionsCheck = store.getters['options/CHECK_OPTIONS'];

        if (optionsCheck === false) {
            await store.dispatch('options/UPDATE_OPTIONS');
        }

        const postsLength = store.getters['posts/GET_POSTS_COUNT'];

        if (postsLength === 0) {
            await store.dispatch('posts/UPDATE_POSTS');
        }

        const { path } = route;
        const pathLangCleaned = path.replace(/^[\/|\/en\/]+/, '');

        let postID = 2;

        if (i18n.locale === 'en') {
            postID = 2166;
        }

        if (typeof $getIDBySlug !== 'undefined') {
            const getPostID = await $getIDBySlug(
                pathLangCleaned.replace(/\/$/, '')
            );

            if (typeof getPostID !== 'undefined' && getPostID !== null) {
                postID = getPostID;
            }
        }

        const postCall = await $axios.$get(`posts/${postID}`, {
            params: {
                culture: i18n.locale,
            },
        });

        if (postCall.isSuccess === false) {
            error({
                statusCode: 404,
                message: i18n.t('Ops, sembra che questa pagina non esista'),
            });

            return;
        }

        const post =
            postCall.isSuccess === true && postCall.payload
                ? postCall.payload.posts[0]
                : null;

        await store.dispatch('ui/UPDATE_IS_COVER_FIRST_SECTION', true);

        await store.dispatch('immobili/UPDATE_COL_COUNT', COL_COUNT);
        await store.dispatch('ui/UPDATE_IS_PAGE_IMMOBILI', true);

        const immobiliCount = store.getters['immobili/GET_IMMOBILI_COUNT'];

        if (immobiliCount === 0) {
            await store.dispatch('immobili/UPDATE_IMMOBILI', null);
        }

        const bd = new BuildData(post, 'page');
        const postData = bd.build();

        return postData;
    } catch (e) {
        console.error(e);

        error({
            statusCode: 404,
            message: i18n.t('Ops, sembra che questa pagina non esista'),
        });
    }
}
