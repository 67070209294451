import luminance from "./luminance";
import tailwindConf from "#tailwind-config";

/**
 * @param {string} themeKey - Theme key
 * @param {boolean} returnUtility - If true, function returns Tailwind CSS utility class
 *
 * @returns {string}
 */
export default function (themeKey, returnUtility = true) {
    const luminanceCheck = 0.7;

    const colors = {
        ...tailwindConf.theme.colors,
    };

    const color = colors[themeKey];

    const luminanceVal = luminance(color);

    if (returnUtility === true) {
        // Custom check for this project. If copy this file in other project,
        // could be necessary remove this part.
        if (themeKey === "secondary") {
            return "text-white";
        }
        // End custom check

        if (luminanceVal > luminanceCheck) {
            return "text-dark";
        }

        return "text-white";
    }

    let d = 0;
    if (luminanceVal > luminanceCheck) {
        d = 0; // bright colors - black font
    } else {
        d = 255; // dark colors - white font
    }

    return `rgb(${d}, ${d}, ${d})`;
}
