//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowLogo",
    props: {
        height: {
            default: "",
            type: String,
        },
        hWhite: {
            default: true,
            type: Boolean,
        },
        type: {
            default: "center",
            type: String,
            validator: (value) => ["center", "lateral"].includes(value),
        },
        white: {
            default: false,
            type: Boolean,
        },
        width: {
            default: "",
            type: String,
        },
    },
    computed: {
        getFillPrimary() {
            if (this.white === true) {
                return "fill-white";
            }

            return "fill-primary";
        },
        getFillSecondary() {
            if (this.white === true && this.hWhite === true) {
                return "fill-white";
            }

            return "fill-secondary";
        },
    },
};
