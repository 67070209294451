import { iubendaConfigIT, iubendaConfigEN } from "~/config";

export default function ({ app }) {
    app.i18n.onBeforeLanguageSwitch = async (
        oldLocale,
        newLocale,
        isInitialSetup,
        context
    ) => {
        context.app.head.htmlAttrs.lang = newLocale;

        const iubendaScript = context.app.head.script.filter(
            (item) => item.hid === "iubenda-script"
        );
        if (iubendaScript && iubendaScript[0]) {
            iubendaScript[0].innerHTML = `
        var _iub = _iub || [];
        _iub.csConfiguration = JSON.parse('${
            newLocale === "en" ? iubendaConfigEN : iubendaConfigIT
        }');
      `;
        }

        await context.store.dispatch("immobili/UPDATE_IMMOBILI", newLocale);
        await context.store.dispatch("menu/UPDATE_LIST", newLocale);
        await context.store.dispatch("menu/UPDATE_LIST_20", newLocale);
    };
}
