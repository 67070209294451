export const state = () => ({
    bounding: {},
    loadCounter: 1,
    isLocomotiveActive: false,
    limit: {
        current: {
            top: 0,
            bottom: 0,
        },
        previous: {
            top: 0,
            bottom: 0,
        },
    },
    pageHeight: 0,
    scroll: {
        current: {
            x: 0,
            y: 0,
            way: "down",
        },
        previous: {
            x: 0,
            y: 0,
            way: "down",
        },
        isScrolling: false,
    },
});

export const getters = {
    getBounding(state) {
        return state.bounding;
    },
    GET_SCROLL(state) {
        return state.currentScroll;
    },
    GET_IS_LOCOMOTIVE_ACTIVE(state) {
        return state.isLocomotiveActive;
    },
    getLoadCounter(state) {
        return state.loadCounter;
    },
    GET_LIMIT(state) {
        return state.limit;
    },
    getPageHeight(state) {
        return state.pageHeight;
    },
};

export const mutations = {
    setBounding(state, value) {
        state.bounding = value;
    },
    setIsLocomotiveActive(state, value) {
        state.isLocomotiveActive = value;
    },
    setLoadCounter(state, value) {
        state.loadCounter = value;
    },
    setLimit(state, value) {
        state.limit = value;
    },
    setPageHeight(state, value) {
        state.pageHeight = value;
    },
    SET_SCROLL(state, value) {
        state.scroll = value;
    },
};

export const actions = {
    updateBounding({ commit }, bounding) {
        commit("setBounding", bounding);
    },
    updateIsLocomotiveActive({ commit }, isLocomotiveActive) {
        commit("setIsLocomotiveActive", isLocomotiveActive);
    },
    updateLoadCounter({ commit }, loadCounter) {
        commit("setLoadCounter", loadCounter);
    },
    UPDATE_LIMIT({ commit }, limit) {
        commit("setLimit", limit);
    },
    updatePageHeight({ commit }, pageHeight) {
        commit("setPageHeight", pageHeight);
    },
    UPDATE_SCROLL({ commit }, scroll) {
        commit("SET_SCROLL", scroll);
    },
};
