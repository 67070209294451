export default {
    // Pagine
    'chi-siamo': 91,
    contatti: 95,
    esperienze: 89,
    homepage: 29,
    'il-lago-di-garda': 87,
    prenota: 97,
    'privacy-policy': 3,
    'proponi-un-immobile': 93,
    'ville-e-appartamenti': 2,

    // Immobili
    'appartamento-alloro': 139,
    'appartamento-betta': 136,
    'villa-belvedere': 124,
    'villa-la-scarpata': 137,
    'villa-limonaia': 140,
    'villa-ondina': 138,
    'villa-tittynino': 209,
};
