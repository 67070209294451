import BuildData from "~/helpers/data/buildData";

export const state = () => ({
    posts: [],
});

export const getters = {
    GET_POSTS(state) {
        return state.posts;
    },
    GET_POSTS_COUNT(state) {
        return state.posts.length;
    },
};

export const mutations = {
    SET_POSTS(state, value) {
        state.posts = value;
    },
};

export const actions = {
    async UPDATE_POSTS({ commit, state }) {
        if (state.posts && state.posts.length > 0) {
            return;
        }

        const posts = [];

        try {
            const apiCall = await this.$axios.$get("/posts", {
                params: {
                    postType: "page",
                    culture: "it",
                },
            });

            if (apiCall.isSuccess === false) {
                return;
            }

            if (apiCall.isSuccess === true && apiCall.payload) {
                if (apiCall.payload.posts && apiCall.payload.posts.length > 0) {
                    [...apiCall.payload.posts].forEach((item) => {
                        const buildData = new BuildData(item, "page");
                        const singlePost = buildData.build();

                        posts.push({
                            ...singlePost,
                        });
                    });
                }
            }

            const apiCallEn = await this.$axios.$get("/posts", {
                params: {
                    postType: "page",
                    culture: "en",
                },
            });

            if (apiCallEn.isSuccess === false) {
                return;
            }

            if (apiCallEn.isSuccess === true && apiCallEn.payload) {
                if (
                    apiCallEn.payload.posts &&
                    apiCallEn.payload.posts.length > 0
                ) {
                    [...apiCallEn.payload.posts].forEach((item) => {
                        const buildData = new BuildData(item, "page");
                        const singlePost = buildData.build();
                        posts.push({
                            ...singlePost,
                        });
                    });
                }
            }

            const apiCall2 = await this.$axios.$get("/posts", {
                params: {
                    postType: "immobili",
                    culture: "it",
                },
            });

            if (apiCall2.isSuccess === false) {
                return;
            }

            if (apiCall2.isSuccess === true && apiCall2.payload) {
                if (
                    apiCall2.payload.posts &&
                    apiCall2.payload.posts.length > 0
                ) {
                    [...apiCall2.payload.posts].forEach((item) => {
                        const buildData = new BuildData(item, "immobile");
                        const singlePost = buildData.build();
                        posts.push({
                            ...singlePost,
                        });
                    });
                }
            }

            const apiCall2En = await this.$axios.$get("/posts", {
                params: {
                    postType: "immobili",
                    culture: "en",
                },
            });

            if (apiCall2En.isSuccess === false) {
                return;
            }

            if (apiCall2En.isSuccess === true && apiCall2En.payload) {
                if (
                    apiCall2En.payload.posts &&
                    apiCall2En.payload.posts.length > 0
                ) {
                    [...apiCall2En.payload.posts].forEach((item) => {
                        const buildData = new BuildData(item, "immobile");
                        const singlePost = buildData.build();
                        posts.push({
                            ...singlePost,
                        });
                    });
                }
            }

            commit("SET_POSTS", posts);
        } catch (e) {
            console.error(e);
        }
    },
};
