//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BowIcons',
    props: {
        icon: {
            default: null,
            type: String,
        },
        // isBow: {
        //   default: true,
        //   type: Boolean
        // },
        isFont: {
            default: true,
            type: Boolean,
        },
        size: {
            default: 'base',
            type: String,
        },
    },
    computed: {
        getIcon() {
            if (this.isFont === false) {
                return '';
            }

            return this.icon;

            // if ( this.isBow === true ) {
            //   if (String(this.icon).match(/^icon-lh/)) {
            //     return this.icon;
            //   }

            //   if (String(this.icon).match(/^bow-/)) {
            //     return 'icon-lh-' + String(this.icon).replace('bow-', '');;
            //   }

            //   if (String(this.icon).match(/^icon-/)) {
            //     return 'icon-lh-' + String(this.icon).replace('icon-', '');;
            //   }
            // }

            // return 'bi bi-' + this.icon;
        },
        getSize() {
            if (this.isFont === false) {
                return '';
            }

            // if (this.size === 'sm') {
            //   return 'text-base';
            // }

            // if (this.size === 'lg') {
            //   return 'text-xl';
            // }

            // if (this.size === 'xl') {
            //   return 'text-4xl';
            // }

            // return 'text-lg';

            return this.size;
        },
    },
};
