import MobileDetect from "mobile-detect";

let isMobile = false;
let isTablet = false;
let isDesktop = false;

if (typeof window !== "undefined") {
    const md = new MobileDetect(window.navigator.userAgent);

    isMobile = md.phone() !== null || md.mobile() === "UnknownMobile";

    isTablet = md.tablet() !== null || md.mobile() === "UnknownTablet";

    isDesktop = !isMobile && !isTablet;
}

export default {
    isMobile,
    isTablet,
    isDesktop,
};
