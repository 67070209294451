//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { checkBreakpoint } from "~/helpers";

export default {
    /* eslint-disable */
    props: ["error"],
    /* eslint-enable */

    data: () => ({
        checkBreakpoint: {
            up: {
                lg: false,
            },
            down: {
                lg: false,
            },
        },
    }),

    computed: {
        ...mapState({
            isNavOpen: ({ ui }) => ui.isNavOpen,
        }),

        ...mapGetters(["ui/GET_IS_NAV_OPEN"]),
    },

    mounted() {
        this.checkBreakpoint.up.lg = checkBreakpoint.up("lg");
        this.checkBreakpoint.down.lg = checkBreakpoint.down("lg");
    },
};
