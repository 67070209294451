import { render, staticRenderFns } from "./error.vue?vue&type=template&id=724907cc&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowHeader: require('/workspace/components/organisms/BowHeader/BowHeader.vue').default,BowButton: require('/workspace/components/atoms/BowButton/BowButton.vue').default,BowFooter: require('/workspace/components/organisms/BowFooter/BowFooter.vue').default})
