/**
 * Get name of Vue component associated to
 * WordPress Gutenberg Block name passed
 *
 * @author Federico Corini <fcorini@bizonweb.it>
 *
 * @param { string } componentType - The name of Gutenberg block or its alias
 *
 * @returns { string } - The name of associated Vue component
 *
 */
const getComponentName = (componentType) => {
    // return 'BowTest';

    switch (componentType) {
        case "bow-lh-activities-list-random":
            return "BowActivitiesList";

        case "bow-lh-content":
            return "BowContentBlock";

        case "bow-lh-content-dx":
            return "BowContentBlockDx";

        case "bow-lh-content-sx":
            return "BowContentBlockSx";

        case "bow-lh-content-list-media-dx":
            return "BowContentListMediaDx";

        case "bow-lh-content-list-media-sx":
            return "BowContentListMediaSx";

        case "bow-lh-content-media-dx":
        case "bow-lh-content-media-toggle-dx":
            return "BowContentMediaDx";

        case "bow-lh-content-media-sx":
        case "bow-lh-content-media-toggle-sx":
            return "BowContentMediaSx";

        case "bow-lh-content-media-dx-2":
        case "bow-lh-content-media-toggle-dx-2":
            return "BowContentMediaDx2";

        case "bow-lh-content-media-sx-2":
        case "bow-lh-content-media-toggle-sx-2":
            return "BowContentMediaSx2";

        case "bow-lh-content-media-dx-sticky":
        case "bow-lh-activities-list-media-dx":
            return "BowContentStickyMediaDx";

        case "bow-lh-content-media-sx-sticky":
        case "bow-lh-activities-list-media-sx":
            return "BowContentStickyMediaSx";

        case "bow-lh-content-2-cols":
        case "content-2-cols":
            return "BowContent2Cols";

        case "bow-lh-cover":
            return "BowCover";

        case "bow-lh-cover-immobile":
            return "BowCoverImmobile";

        case "bow-lh-cover-preview":
            return "BowCoverPreview";

        case "bow-lh-cover-slider":
        case "cover-slider":
            return "BowCoverSlider";

        case "bow-lh-cta-center":
            return "BowCTABlockCenter";

        case "bow-lh-cta-left":
            return "BowCTABlockSx";

        case "bow-lh-form-contacts":
            return "BowFormContact";

        case "bow-lh-form-propose":
            return "BowFormPropose";

        case "bow-lh-gallery":
            return "BowGallery";

        case "bow-lh-immobili-list":
            return "BowImmobiliList";

        case "bow-lh-info-immobile":
            return "BowInfoImmobile";

        case "bow-lh-items-list":
            return "BowItemsListBlock";

        case "bow-lh-media-list":
            return "BowMediaList";

        case "bow-lh-reviews":
            return "BowReviewsBlock";

        case "bow-lh-reviews-list":
            return "BowReviewsList";

        case "bow-lh-servizi-immobile":
            return "BowDetailsBlock";

        default:
            return "BowContentBlock";
    }
};

export default getComponentName;
