//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";

export default {
    name: "BowSocials",

    props: {
        vertical: {
            default: false,
            type: Boolean,
        },
    },

    data: () => ({
        socials: [],
    }),

    fetch() {
        if (this.social.url_instagram && this.social.url_instagram !== "") {
            this.socials.push({
                label: "Instagram",
                link: this.social.url_instagram,
            });
        }

        if (this.social.url_facebook && this.social.url_facebook !== "") {
            this.socials.push({
                label: "Facebook",
                link: this.social.url_facebook,
            });
        }

        if (this.social.url_youtube && this.social.url_youtube !== "") {
            this.socials.push({
                label: "YouTube",
                link: this.social.url_youtube,
            });
        }
    },

    computed: {
        ...mapState({
            social: ({ options }) => options.social,
        }),

        ...mapGetters(["options/GET_SOCIAL"]),
    },
};
