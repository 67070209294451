//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";

export default {
    name: "BowNav",
    props: {
        htmlTag: {
            default: null,
            type: String,
        },
        isOpen: {
            default: false,
            type: Boolean,
        },
    },

    data: () => ({
        coverHeight: 0,
    }),

    // async fetch() {
    //   if ( this.businessData === null || this.social === null ) {
    //     await this.$store.dispatch('options/UPDATE_OPTIONS');
    //   }
    // },

    computed: {
        ...mapState({
            businessData: ({ options }) => options.businessData,
        }),

        ...mapGetters(["options/GET_BUSINESS_DATA"]),
    },

    watch: {
        isOpen(value, oldValue) {
            if (value === true) {
                this.$anime({
                    targets: this.$el.querySelector(".o-nav__inner__social"),
                    delay: 500,
                    duration: 1500,
                    easing: "easeOutSine",
                    opacity: [0, 1],
                });

                this.$anime({
                    targets: this.$el.querySelector(".o-nav__cover"),
                    delay: 500,
                    duration: 1500,
                    easing: "easeOutSine",
                    opacity: [0, 1],
                });
            } else {
                this.$el.querySelector(
                    ".o-nav__inner__social"
                ).style.opacity = 0;
                this.$el.querySelector(".o-nav__cover").style.opacity = 0;
            }
        },
    },

    mounted() {
        const cover = document.querySelector(".o-nav__cover");
        if (cover) {
            this.coverHeight = cover.getBoundingClientRect().height;
        }
    },
};
