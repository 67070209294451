//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import "lazysizes";

export default {
    name: "BowImage",
    props: {
        alt: {
            default: "",
            type: String,
        },
        classImage: {
            default: null,
            type: [Array, String],
        },
        classImageExtra: {
            default: "",
            type: [Array, String],
        },
        classPicture: {
            default: null,
            type: [Array, String],
        },
        image: {
            default: () => ({
                // id: 'photo-1601235116901-2dc694b4cb1b',
                // ixid: 'MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8',
                // provider: 'unsplash',
                height: null,
                provider: "wordpress",
                url: null,
                width: null,
            }),
            type: Object,
        },
        radius: {
            default: true,
            type: Boolean,
        },
        size: {
            default: "bow-thumb-default",
            type: String,
        },
        sizes: {
            // default: 'sm:100vw md:50vw lg:400px',
            default: "",
            type: String,
        },
        styleImage: {
            default: null,
            type: Object,
        },
    },
    data: () => ({}),
    computed: {
        getSrc() {
            // let src = '';

            // if (this.image.provider === 'unsplash') {
            //   src = `/unsplash/${this.image.id}?ixid=${this.image.ixid}&ixlib=rb-1.2.1&auto=format&fit=crop&w=${this.image.width}${this.image.height && this.image.height !== '' ? `&h=${this.image.height}` : ''}&q=80`;
            // }

            // if (this.image.provider === 'static' || this.image.provider === 'wordpress') {
            //   src = this.image.url;
            // }

            if (this.image) {
                if (this.size && this.size !== "") {
                    const sizes = this.image.sizes
                        ? { ...this.image.sizes }
                        : {};

                    if (
                        Object.prototype.hasOwnProperty.call(
                            sizes,
                            this.size
                        ) === true
                    ) {
                        return `${this.image.sizes[this.size]}`;
                    }
                }

                return `${this.image.url}`;
            }

            return "";
        },
    },
};
