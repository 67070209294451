export const state = () => ({
    list: [],
    list20: [],
});

export const getters = {
    GET_LIST(state) {
        return state.list;
    },

    GET_LIST_COUNT(state) {
        return state.list.length;
    },

    GET_LIST_20(state) {
        return state.list20;
    },

    GET_LIST_20_COUNT(state) {
        return state.list20.length;
    },
};

export const mutations = {
    SET_LIST(state, value) {
        state.list = value;
    },

    SET_LIST_20(state, value) {
        state.list20 = value;
    },
};

export const actions = {
    async UPDATE_LIST({ commit, state }, lang = null) {
        // if ( state.list && state.list.length > 0 ) {
        //   return;
        // }

        const list = [];

        let langToUse = this.app.i18n.locale;
        if (lang !== null && typeof lang !== "undefined" && lang !== "") {
            langToUse = lang;
        }

        try {
            const apiCall = await this.$axios.$get("/menus/3", {
                params: {
                    culture: langToUse,
                },
            });

            if (apiCall.isSuccess === true && apiCall.payload) {
                if (apiCall.payload.items && apiCall.payload.items.length > 0) {
                    apiCall.payload.items.forEach((item) => {
                        if (item.menuItemInfos.status === "publish") {
                            const parentSlug =
                                item.postInfos.type === "immobili"
                                    ? "immobili/"
                                    : "";

                            let url = `/${parentSlug}${item.postInfos.slug}`;
                            let titleItem = item.postInfos.title;

                            if (item.menuItemInfos.type === "custom") {
                                url = item.menuItemInfos.url;
                                titleItem = item.menuItemInfos.title;
                            }

                            if (item.menuItemInfos.type === "taxonomy") {
                                url = `/${parentSlug}${item.menuItemInfos.slug}`;
                                titleItem = item.menuItemInfos.title;
                            }

                            list.push({
                                link: url,
                                label: titleItem,
                                title: titleItem,
                                isCustom: item.menuItemInfos.type === "custom",
                            });
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }

        commit("SET_LIST", list);
    },

    async UPDATE_LIST_20({ commit, state }, lang = null) {
        const list = [];

        let langToUse = this.app.i18n.locale;
        if (lang !== null && typeof lang !== "undefined" && lang !== "") {
            langToUse = lang;
        }

        try {
            const apiCall = await this.$axios.$get("/menus/20", {
                params: {
                    culture: langToUse,
                },
            });

            if (apiCall.isSuccess === true && apiCall.payload) {
                if (apiCall.payload.items && apiCall.payload.items.length > 0) {
                    apiCall.payload.items.forEach((item) => {
                        if (item.menuItemInfos.status === "publish") {
                            const parentSlug =
                                item.postInfos.type === "immobili"
                                    ? "immobili/"
                                    : "";

                            let url = `/${parentSlug}${item.postInfos.slug}`;
                            let titleItem = item.postInfos.title;

                            if (item.menuItemInfos.type === "custom") {
                                url = item.menuItemInfos.url;
                                titleItem = item.menuItemInfos.title;
                            }

                            if (item.menuItemInfos.type === "taxonomy") {
                                url = `/${parentSlug}${item.menuItemInfos.slug}`;
                                titleItem = item.menuItemInfos.title;
                            }

                            list.push({
                                link: url,
                                label: titleItem,
                                title: titleItem,
                                isCustom: item.menuItemInfos.type === "custom",
                            });
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }

        commit("SET_LIST_20", list);
    },
};
