export const state = () => ({
    filters: {
        tipologia: {
            ville: false,
            appartamenti: false,
            case: false,
        },
        ospiti: 0,
        servizi: {
            parcheggio: false,
            piscina: false,
            giardino: false,
            spiaggia: false,
            animali: false,
        },
    },
    activeFilters: 0,
});

export const getters = {
    GET_FILTERS(state) {
        return state.filters;
    },
    GET_ACTIVE_FILTERS(state) {
        return state.activeFilters;
    },
};

export const mutations = {
    SET_FILTERS(state, value) {
        state.filters = {
            ...value,
        };
    },
    SET_ACTIVE_FILTERS(state, value) {
        state.activeFilters = value;
    },
};

export const actions = {
    UPDATE_FILTERS({ commit }, filters) {
        commit('SET_FILTERS', filters);
    },
    UPDATE_ACTIVE_FILTERS({ commit }, value) {
        commit('SET_ACTIVE_FILTERS', value);
    },
    RESET_FILTERS({ commit }) {
        commit('SET_FILTERS', {
            tipologia: {
                ville: false,
                appartamenti: false,
                case: false,
            },
            ospiti: 0,
            servizi: {
                parcheggio: false,
                piscina: false,
                giardino: false,
                spiaggia: false,
                animali: false,
            },
        });

        commit('SET_ACTIVE_FILTERS', 0);
    },
};
