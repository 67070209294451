import { splitArrayIntoGroups } from '~/helpers/array';
import BuildData from '~/helpers/data/buildData';

export const state = () => ({
    colCount: 3,
    immobili: [],
    immobiliFiltered: [],
    immobiliSplitted: [],
});

export const getters = {
    GET_COL_COUNT(state) {
        return state.colCount;
    },

    GET_IMMOBILI(state) {
        return state.immobili;
    },

    GET_IMMOBILI_COUNT(state) {
        return state.immobili.length;
    },

    GET_IMMOBILI_FILTERED(state) {
        return state.immobiliFiltered;
    },

    GET_IMMOBILI_SPLITTED(state) {
        return state.immobiliSplitted;
    },
};

export const mutations = {
    SET_COL_COUNT(state, value) {
        state.colCount = value;
    },

    SET_IMMOBILI(state, value) {
        state.immobili = value;
    },

    SET_IMMOBILI_FILTERED(state, value) {
        state.immobiliFiltered = value;
    },

    SET_IMMOBILI_SPLITTED(state, value) {
        state.immobiliSplitted = [
            ...splitArrayIntoGroups(value, state.colCount),
        ];
    },
};

export const actions = {
    UPDATE_COL_COUNT({ commit }, colCount) {
        commit('SET_COL_COUNT', colCount);
    },

    UPDATE_IMMOBILI_FILTERED({ commit }, immobili) {
        commit('SET_IMMOBILI_FILTERED', immobili);
        commit('SET_IMMOBILI_SPLITTED', immobili);
    },

    RESET_IMMOBILI_FILTERED({ commit, state, dispatch }) {
        dispatch('UPDATE_IMMOBILI_FILTERED', state.immobili);
    },

    async UPDATE_IMMOBILI({ commit, state, dispatch }, lang = null) {
        const newFullList = [];
        let langToUse = this.app.i18n.locale;

        if (lang !== null && typeof lang !== 'undefined' && lang !== '') {
            langToUse = lang;
        }

        try {
            const apiResult = await this.$axios.$get('/posts', {
                params: {
                    postType: 'immobili',
                    culture: langToUse,
                },
            });

            if (apiResult.isSuccess === false) {
                return;
            }

            if (apiResult.isSuccess === true) {
                if (
                    apiResult.payload &&
                    apiResult.payload.posts &&
                    apiResult.payload.posts.length > 0
                ) {
                    let isWpAdmin = false;

                    if (process.client) {
                        isWpAdmin =
                            document.cookie.search(/wordpress_logged_in_/) !==
                            -1;
                    }

                    apiResult.payload.posts.forEach((item) => {
                        if (
                            isWpAdmin === true &&
                            item.post_status !== 'draft' &&
                            item.post_status !== 'publish'
                        ) {
                            return;
                        }

                        if (item.post_status !== 'publish') {
                            return;
                        }

                        const buildData = new BuildData(item, 'immobile');
                        const singleImmobile = buildData.build();

                        const slug = item.post_name;

                        const subtitle =
                            singleImmobile.services.citta &&
                            singleImmobile.services.citta.value
                                ? singleImmobile.services.citta.value
                                : null;

                        const servizi = {
                            tipologia: null,
                            ospiti: null,
                            ospitiFilters: null,
                            camere: null,
                            bagni: null,
                            parcheggio: false,
                            piscina: false,
                            giardino: false,
                            spiaggiaPrivata: false,
                            animali: false,
                        };

                        if (singleImmobile.tipologiaImmobile) {
                            servizi.tipologia =
                                singleImmobile.tipologiaImmobile.toLowerCase();
                        }

                        if (
                            singleImmobile.services.ospiti &&
                            singleImmobile.services.ospiti.value
                        ) {
                            servizi.ospiti =
                                singleImmobile.services.ospiti.value;
                        }

                        if (
                            singleImmobile.services.ospiti_filtri &&
                            singleImmobile.services.ospiti_filtri.value
                        ) {
                            servizi.ospitiFilters =
                                singleImmobile.services.ospiti_filtri.value;
                        }

                        if (
                            singleImmobile.services.camere &&
                            singleImmobile.services.camere.value
                        ) {
                            servizi.camere =
                                singleImmobile.services.camere.value;
                        }

                        if (
                            singleImmobile.services.bagni &&
                            singleImmobile.services.bagni.value
                        ) {
                            servizi.bagni = singleImmobile.services.bagni.value;
                        }

                        if (
                            singleImmobile.services.parcheggioPrivato &&
                            singleImmobile.services.parcheggioPrivato.value
                        ) {
                            servizi.parcheggio =
                                singleImmobile.services.parcheggioPrivato
                                    .value === '1';
                        }

                        if (
                            singleImmobile.services.piscinaCondivisa &&
                            singleImmobile.services.piscinaCondivisa.value
                        ) {
                            servizi.piscina =
                                singleImmobile.services.piscinaCondivisa
                                    .value === '1';
                        }

                        if (
                            singleImmobile.services.piscinaPrivata &&
                            singleImmobile.services.piscinaPrivata.value
                        ) {
                            // If common pool is true, skip the check for private, because it's already ok for filter
                            if (servizi.piscina !== true) {
                                servizi.piscina =
                                    singleImmobile.services.piscinaPrivata
                                        .value === '1';
                            }
                        }

                        if (
                            singleImmobile.services.giardino &&
                            singleImmobile.services.giardino.value
                        ) {
                            servizi.giardino =
                                singleImmobile.services.giardino.value === '1';
                        }

                        if (
                            singleImmobile.services.spiaggiaPrivata &&
                            singleImmobile.services.spiaggiaPrivata.value
                        ) {
                            servizi.spiaggiaPrivata =
                                singleImmobile.services.spiaggiaPrivata
                                    .value === '1';
                        }

                        if (
                            singleImmobile.services.animaliAmmessi &&
                            singleImmobile.services.animaliAmmessi.value
                        ) {
                            servizi.animali =
                                singleImmobile.services.animaliAmmessi.value ===
                                '1';
                        }

                        newFullList.push({
                            ...singleImmobile,
                            card: {
                                pretitle: singleImmobile.tipologiaImmobile,
                                title: singleImmobile.nomeImmobile,
                                subtitle,
                                image:
                                    singleImmobile.featuredImage &&
                                    singleImmobile.featuredImage.image
                                        ? {
                                            ...singleImmobile.featuredImage
                                                .image,
                                            alt: `${singleImmobile.tipologiaImmobile} ${singleImmobile.nomeImmobile}`,
                                        }
                                        : null,
                                link: `/immobili/${slug}`,
                                coordinates: singleImmobile.coordinates,
                                servizi,
                            },
                        });
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }

        commit('SET_IMMOBILI', newFullList);
        dispatch('UPDATE_IMMOBILI_FILTERED', newFullList);
    },
};
