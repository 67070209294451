export default function ({ $config, store }) {
    let mapboxKey = null;

    if ($config) {
        const hasMapboxKey = Object.prototype.hasOwnProperty.call($config, 'MAPBOX_API_KEY');

        if (hasMapboxKey === true) {
            mapboxKey = $config.MAPBOX_API_KEY;
        }
    }

    if (mapboxKey !== null && typeof mapboxKey !== 'undefined') {
        store.dispatch('map/UPDATE_MAPBOX_KEY', mapboxKey);
    }
}
