var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:[
        'o-nav',
        'duration-500 ease-out',
        _vm.isOpen
            ? 'transform translate-x-0'
            : 'pointer-events-none transform -translate-x-full' ]},[_c('div',{staticClass:"o-nav__wrapper"},[_c('div',{staticClass:"o-nav__inner"},[_c('div',{staticClass:"o-nav__inner__left"},[_c('div',{staticClass:"o-nav__inner__social"},[_c('BowSocials',{attrs:{"vertical":true}})],1),_vm._v(" "),_c('div',{staticClass:"o-nav__inner__menu"},[_c('BowMenu',{attrs:{"isNavOpen":_vm.isOpen}})],1)]),_vm._v(" "),_c('BowNavImage',{staticClass:"o-nav__cover"})],1),_vm._v(" "),_c('div',{staticClass:"border-t-2 border-t-white mt-16 py-16 text-white lg:hidden"},[_c('div',{staticClass:"flex items-center text-lg lg:hidden"},[_c('NuxtLink',{attrs:{"to":_vm.switchLocalePath('it')}},[_vm._v(" IT ")]),_vm._v(" "),_c('span',{staticClass:"inline-block mx-4"},[_vm._v("|")]),_vm._v(" "),_c('NuxtLink',{attrs:{"to":_vm.switchLocalePath('en')}},[_vm._v(" EN ")])],1),_vm._v(" "),_c('div',{staticClass:"mt-7 w-full"},[_c('BowSocials')],1),_vm._v(" "),(_vm.businessData)?_c('BowText',{staticClass:"mt-7 w-full"},[_c('p',{staticClass:"text-left text-sm"},[(_vm.businessData.ragione_sociale)?_c('strong',[_vm._v("\n                        "+_vm._s(_vm.businessData.ragione_sociale)+"\n                    ")]):_vm._e(),_vm._v("\n                    -\n                    "+_vm._s(_vm.businessData.iva
                            ? ((_vm.$t("p.iva")) + " " + (_vm.businessData.iva))
                            : "")+"\n                    -\n                    "),_c('a',{attrs:{"href":"","target":"_blank","rel":"noopener"}},[_vm._v("\n                        "+_vm._s(_vm.$t("Privacy policy"))+"\n                    ")]),_vm._v("\n                    -\n                    "),_c('a',{attrs:{"href":"","target":"_blank","rel":"noopener"}},[_vm._v("\n                        "+_vm._s(_vm.$t("Cookie policy"))+"\n                    ")]),_vm._v("\n                    -\n                    "),_c('a',{attrs:{"href":"","target":"_blank","rel":"noopener"}},[_vm._v("\n                        "+_vm._s(_vm.$t("Preferenze cookie"))+"\n                    ")]),_vm._v("\n                    - Created by:\n                    "),_c('a',{attrs:{"href":"https://www.bizonweb.it","rel":"noopener","target":"_blank"}},[_vm._v("bizOnweb.it")])])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }