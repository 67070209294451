import Vue from "vue";

/**
 * Get numeric value from Tailwind config breakpoint
 *
 * @param {String} breakpoint - Key name of breakpoint
 *
 * @returns {Number|String}
 *
 */
export function getIntBreakpoint(breakpoint) {
    const stringScreen =
        Vue.prototype.$tailwindConfig.theme.screens[breakpoint];

    const matchValue = String(stringScreen).match(/^[0-9]+/);

    if (Array.isArray(matchValue) && matchValue[0]) {
        return parseInt(matchValue[0], 10);
    }

    return stringScreen;
}

/**
 * Check if current screen size is bigger than provided breakpoint size
 *
 * @param {String} breakpoint - Key name of breakpoint
 *
 * @returns {Boolean}
 *
 */
export function breakpointUp(breakpoint) {
    if (
        process.client &&
        typeof window !== "undefined" &&
        Vue.prototype.$tailwindConfig
    ) {
        if (window.screen.width >= getIntBreakpoint(breakpoint)) {
            return true;
        }
    }

    return false;
}

/**
 * Check if current screen size is smaller than provided breakpoint size
 *
 * @param {String} breakpoint - Key name of breakpoint
 *
 * @returns {Boolean}
 *
 */
export function breakpointDown(breakpoint) {
    if (
        process.client &&
        typeof window !== "undefined" &&
        Vue.prototype.$tailwindConfig
    ) {
        if (window.screen.width < getIntBreakpoint(breakpoint)) {
            return true;
        }
    }

    return false;
}

/**
 * Check if current screen size is included into provided breakpoint interval
 *
 * @param {String} breakpointSmall - Key name of smaller breakpoint
 * @param {String} breakpointBig - Key name of bigger breakpoint
 *
 * @returns {Boolean}
 *
 */
export function breakpointBetween(breakpointSmall, breakpointBig) {
    if (
        process.client &&
        typeof window !== "undefined" &&
        Vue.prototype.$tailwindConfig
    ) {
        if (
            window.screen.width >= getIntBreakpoint(breakpointSmall) &&
            window.screen.width >= getIntBreakpoint(breakpointBig)
        ) {
            return true;
        }
    }

    return false;
}

export default {
    up: (breakpoint) => breakpointUp(breakpoint),
    down: (breakpoint) => breakpointDown(breakpoint),
    between: (breakpointSmall, breakpointBig) =>
        breakpointBetween(breakpointSmall, breakpointBig),
};
