//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { themeValidation } from "../../../helpers/propsValidators";
import { getButtonTheme } from "../../../helpers/getTheme/getTheme";

export default {
    name: "BowButton",
    props: {
        bold: {
            default: true,
            type: Boolean,
        },
        hollow: {
            default: false,
            type: Boolean,
        },
        hasInnerSpan: {
            default: true,
            type: Boolean,
        },
        href: {
            default: "#",
            type: String,
        },
        icon: {
            default: null,
            type: String,
        },
        rounded: {
            default: null,
            type: [String, Boolean],
        },
        size: {
            default: null,
            type: String,
        },
        theme: {
            default: "primary",
            type: String,
            validation: (value) => themeValidation(value),
        },
        title: {
            default: "",
            type: String,
        },
        type: {
            default: "button",
            type: String,
            validator: (value) =>
                ["button", "link", "submit", "link-ext"].includes(value),
        },
        uppercase: {
            default: false,
            type: Boolean,
        },
    },
    data: () => ({}),
    computed: {
        generateClassname() {
            const className = {
                borderRadius: "",
                size: "",
                theme: "",
                uppercase: "",
                value: "",
            };

            className.theme = "bg-primary border border-primary text-white";
            className.size = "px-6 py-3";

            // Manage theme
            className.theme = getButtonTheme(this.theme, this.hollow);

            // Manage size
            if (this.size) {
                if (this.size === "lg") {
                    className.size = "px-6 py-4";
                }

                if (this.size === "sm") {
                    className.size = "px-5 py-2.5";
                }

                if (this.size === "xs") {
                    className.size = "px-3 py-1.5 text-sm";
                }

                if (this.size === "media-toggle") {
                    className.size =
                        "px-2.5 py-1.5 text-sm xl:text-xs 2xl:text-sm";
                }
            }

            // Manage border-radius
            className.borderRadius = "rounded-button";

            if (this.rounded) {
                if (this.rounded === false) {
                    className.borderRadius = "rounded-none";
                } else if (this.rounded !== "") {
                    className.borderRadius = this.rounded;
                }
            }

            // Manage uppercase
            if (this.uppercase === true) {
                className.uppercase = "uppercase";
            }

            return [
                "flex items-center justify-center",
                this.bold ? "font-bold" : "",
                className.theme,
                className.size,
                className.borderRadius,
                className.uppercase,
            ].join(" ");
        },

        generateInnerSpanClassname() {
            let className = "text-base";

            switch (this.size) {
                case "lg":
                case "sm":
                    break;

                case "xs":
                    className = "text-sm";
                    break;

                case "media-toggle":
                    className = "text-sm xl:text-xs 2xl:text-sm";
                    break;
            }

            return className;
        },
    },
};
