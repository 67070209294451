export default [
    {
        key: 'citta',
        cfKey: '_citta',
        icon: 'location',
        label: null,
        space: false,
    },
    {
        key: 'ospiti',
        cfKey: '_ospiti',
        icon: 'ospiti',
        label: 'ospiti',
        space: true,
    },
    {
        key: 'ospiti_filtri',
        cfKey: '_ospiti_filtri',
        icon: null,
        label: null,
        space: false,
    },
    {
        key: 'camere',
        cfKey: '_camere',
        icon: 'camere',
        label: 'camere',
        space: true,
    },
    {
        key: 'bagni',
        cfKey: '_bagni',
        icon: 'bagni',
        label: 'bagni',
        space: true,
    },
    {
        key: 'superficie',
        cfKey: '_superficie',
        icon: 'superficie',
        label: 'mq di casa',
        space: false,
    },
    {
        key: 'giardino',
        cfKey: '_giardino',
        icon: 'giardino',
        label: null,
        space: false,
    },
    {
        key: 'superficieGiardino',
        cfKey: '_superficie_giardino',
        icon: 'giardino',
        label: 'mq di giardino',
        space: false,
    },
    {
        key: 'spiaggiaPrivata',
        cfKey: '_spiaggia_privata',
        icon: 'spiaggia',
        label: 'Spiaggia privata',
        space: false,
    },
    {
        key: 'animaliAmmessi',
        cfKey: '_animali_ammessi',
        icon: 'animali',
        label: 'Animali ammessi',
        space: false,
    },
    {
        key: 'parcheggioPrivato',
        cfKey: '_parcheggio_privato',
        icon: null,
        label: null,
        space: false,
    },
    {
        key: 'parcheggioPrivatoTipologia',
        cfKey: '_parcheggio_privato_tipologia',
        icon: 'parcheggio',
        label: 'Parcheggio privato',
        space: false,
    },
    {
        key: 'lavanderia',
        cfKey: '_lavanderia',
        icon: 'lavanderia',
        label: 'Lavanderia',
        space: false,
    },
    {
        key: 'ariaCondizionata',
        cfKey: '_aria_condizionata',
        icon: 'aria',
        label: 'Aria condizionata',
        space: false,
    },
    {
        key: 'wifi',
        cfKey: '_wifi',
        icon: 'wifi',
        label: 'Wi-Fi',
        space: false,
    },
    {
        key: 'barbeque',
        cfKey: '_bbq',
        icon: 'barbeque',
        label: 'Barbeque',
        space: false,
    },
    {
        key: 'jacuzziPrivata',
        cfKey: '_jacuzzi_privata',
        icon: 'jacuzzi',
        label: 'Jacuzzi privata',
        space: false,
    },
    {
        key: 'terrazzo',
        cfKey: '_terrazzo',
        icon: 'terrazzo',
        label: 'Terrazzo',
        space: false,
    },
    {
        key: 'sauna',
        cfKey: '_sauna',
        icon: 'sauna',
        label: 'Sauna',
        space: false,
    },
    {
        key: 'piscinaCondivisa',
        cfKey: '_piscina_condivisa',
        icon: 'piscina',
        label: 'Piscina condivisa',
        space: false,
    },
    {
        key: 'piscinaPrivata',
        cfKey: '_piscina_privata',
        icon: 'piscina',
        label: 'Piscina privata',
        space: false,
    },
];
