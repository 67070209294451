export const BowAnimation = () => import('../../components/base/BowAnimation/BowAnimation.vue' /* webpackChunkName: "components/bow-animation" */).then(c => wrapFunctional(c.default || c))
export const BowAnimationBowAnimation = () => import('../../components/base/BowAnimation/_BowAnimation.vue' /* webpackChunkName: "components/bow-animation-bow-animation" */).then(c => wrapFunctional(c.default || c))
export const BowLocomotiveScroll = () => import('../../components/base/BowLocomotiveScroll/BowLocomotiveScroll.vue' /* webpackChunkName: "components/bow-locomotive-scroll" */).then(c => wrapFunctional(c.default || c))
export const BowLocomotiveScrollCols = () => import('../../components/base/BowLocomotiveScrollCols/BowLocomotiveScrollCols.vue' /* webpackChunkName: "components/bow-locomotive-scroll-cols" */).then(c => wrapFunctional(c.default || c))
export const BowLocomotiveScrollSingleCol = () => import('../../components/base/BowLocomotiveScrollCols/BowLocomotiveScrollSingleCol.vue' /* webpackChunkName: "components/bow-locomotive-scroll-single-col" */).then(c => wrapFunctional(c.default || c))
export const BowLoop = () => import('../../components/base/BowLoop/BowLoop.vue' /* webpackChunkName: "components/bow-loop" */).then(c => wrapFunctional(c.default || c))
export const BowScroll = () => import('../../components/base/BowScroll/BowScroll.vue' /* webpackChunkName: "components/bow-scroll" */).then(c => wrapFunctional(c.default || c))
export const BowScrollCols = () => import('../../components/base/BowScrollCols/BowScrollCols.vue' /* webpackChunkName: "components/bow-scroll-cols" */).then(c => wrapFunctional(c.default || c))
export const BowScrollSingleCol = () => import('../../components/base/BowScrollCols/BowScrollSingleCol.vue' /* webpackChunkName: "components/bow-scroll-single-col" */).then(c => wrapFunctional(c.default || c))
export const BowSection = () => import('../../components/base/BowSection/BowSection.vue' /* webpackChunkName: "components/bow-section" */).then(c => wrapFunctional(c.default || c))
export const BowSticky = () => import('../../components/base/BowSticky/BowSticky.vue' /* webpackChunkName: "components/bow-sticky" */).then(c => wrapFunctional(c.default || c))
export const BowWrapper = () => import('../../components/base/BowWrapper/BowWrapper.vue' /* webpackChunkName: "components/bow-wrapper" */).then(c => wrapFunctional(c.default || c))
export const BowButton = () => import('../../components/atoms/BowButton/BowButton.vue' /* webpackChunkName: "components/bow-button" */).then(c => wrapFunctional(c.default || c))
export const BowButtonCircle = () => import('../../components/atoms/BowButtonCircle/BowButtonCircle.vue' /* webpackChunkName: "components/bow-button-circle" */).then(c => wrapFunctional(c.default || c))
export const BowFiltersToggle = () => import('../../components/atoms/BowFiltersToggle/BowFiltersToggle.vue' /* webpackChunkName: "components/bow-filters-toggle" */).then(c => wrapFunctional(c.default || c))
export const BowHeading = () => import('../../components/atoms/BowHeading/BowHeading.vue' /* webpackChunkName: "components/bow-heading" */).then(c => wrapFunctional(c.default || c))
export const BowIconIdromassaggio = () => import('../../components/atoms/BowIcons/BowIconIdromassaggio.vue' /* webpackChunkName: "components/bow-icon-idromassaggio" */).then(c => wrapFunctional(c.default || c))
export const BowIcons = () => import('../../components/atoms/BowIcons/BowIcons.vue' /* webpackChunkName: "components/bow-icons" */).then(c => wrapFunctional(c.default || c))
export const BowImage = () => import('../../components/atoms/BowImage/BowImage.vue' /* webpackChunkName: "components/bow-image" */).then(c => wrapFunctional(c.default || c))
export const BowInput = () => import('../../components/atoms/BowInput/BowInput.vue' /* webpackChunkName: "components/bow-input" */).then(c => wrapFunctional(c.default || c))
export const BowLoadingBar = () => import('../../components/atoms/BowLoadingBar/BowLoadingBar.vue' /* webpackChunkName: "components/bow-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const BowLogo = () => import('../../components/atoms/BowLogo/BowLogo.vue' /* webpackChunkName: "components/bow-logo" */).then(c => wrapFunctional(c.default || c))
export const BowMap = () => import('../../components/atoms/BowMap/BowMap.vue' /* webpackChunkName: "components/bow-map" */).then(c => wrapFunctional(c.default || c))
export const BowMarker = () => import('../../components/atoms/BowMap/BowMarker.vue' /* webpackChunkName: "components/bow-marker" */).then(c => wrapFunctional(c.default || c))
export const BowMenuLink = () => import('../../components/atoms/BowMenuLink/BowMenuLink.vue' /* webpackChunkName: "components/bow-menu-link" */).then(c => wrapFunctional(c.default || c))
export const BowMenuToggle = () => import('../../components/atoms/BowMenuToggle/BowMenuToggle.vue' /* webpackChunkName: "components/bow-menu-toggle" */).then(c => wrapFunctional(c.default || c))
export const BowOverlay = () => import('../../components/atoms/BowOverlay/BowOverlay.vue' /* webpackChunkName: "components/bow-overlay" */).then(c => wrapFunctional(c.default || c))
export const BowRing = () => import('../../components/atoms/BowRing/BowRing.vue' /* webpackChunkName: "components/bow-ring" */).then(c => wrapFunctional(c.default || c))
export const BowSelect = () => import('../../components/atoms/BowSelect/BowSelect.vue' /* webpackChunkName: "components/bow-select" */).then(c => wrapFunctional(c.default || c))
export const BowSkeleton = () => import('../../components/atoms/BowSkeleton/BowSkeleton.vue' /* webpackChunkName: "components/bow-skeleton" */).then(c => wrapFunctional(c.default || c))
export const BowSocials = () => import('../../components/atoms/BowSocials/BowSocials.vue' /* webpackChunkName: "components/bow-socials" */).then(c => wrapFunctional(c.default || c))
export const BowText = () => import('../../components/atoms/BowText/BowText.vue' /* webpackChunkName: "components/bow-text" */).then(c => wrapFunctional(c.default || c))
export const BowToggle = () => import('../../components/atoms/BowToggle/BowToggle.vue' /* webpackChunkName: "components/bow-toggle" */).then(c => wrapFunctional(c.default || c))
export const BowVideo = () => import('../../components/atoms/BowVideo/BowVideo.vue' /* webpackChunkName: "components/bow-video" */).then(c => wrapFunctional(c.default || c))
export const BowCallToAction = () => import('../../components/molecules/BowCallToAction/BowCallToAction.vue' /* webpackChunkName: "components/bow-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const BowCard = () => import('../../components/molecules/BowCard/BowCard.vue' /* webpackChunkName: "components/bow-card" */).then(c => wrapFunctional(c.default || c))
export const BowCardWide = () => import('../../components/molecules/BowCardWide/BowCardWide.vue' /* webpackChunkName: "components/bow-card-wide" */).then(c => wrapFunctional(c.default || c))
export const BowCoverImage = () => import('../../components/molecules/BowCoverImage/BowCoverImage.vue' /* webpackChunkName: "components/bow-cover-image" */).then(c => wrapFunctional(c.default || c))
export const BowFeatureGroup = () => import('../../components/molecules/BowFeatureGroup/BowFeatureGroup.vue' /* webpackChunkName: "components/bow-feature-group" */).then(c => wrapFunctional(c.default || c))
export const BowFeature = () => import('../../components/molecules/BowFeature/BowFeature.vue' /* webpackChunkName: "components/bow-feature" */).then(c => wrapFunctional(c.default || c))
export const BowFilters = () => import('../../components/molecules/BowFilters/BowFilters.vue' /* webpackChunkName: "components/bow-filters" */).then(c => wrapFunctional(c.default || c))
export const BowMedia = () => import('../../components/molecules/BowMedia/BowMedia.vue' /* webpackChunkName: "components/bow-media" */).then(c => wrapFunctional(c.default || c))
export const BowMenu = () => import('../../components/molecules/BowMenu/BowMenu.vue' /* webpackChunkName: "components/bow-menu" */).then(c => wrapFunctional(c.default || c))
export const BowNavImage = () => import('../../components/molecules/BowNavImage/BowNavImage.vue' /* webpackChunkName: "components/bow-nav-image" */).then(c => wrapFunctional(c.default || c))
export const BowReview = () => import('../../components/molecules/BowReviews/BowReview.vue' /* webpackChunkName: "components/bow-review" */).then(c => wrapFunctional(c.default || c))
export const BowReviews = () => import('../../components/molecules/BowReviews/BowReviews.vue' /* webpackChunkName: "components/bow-reviews" */).then(c => wrapFunctional(c.default || c))
export const BowSlide = () => import('../../components/molecules/BowSlider/BowSlide.vue' /* webpackChunkName: "components/bow-slide" */).then(c => wrapFunctional(c.default || c))
export const BowSlider = () => import('../../components/molecules/BowSlider/BowSlider.vue' /* webpackChunkName: "components/bow-slider" */).then(c => wrapFunctional(c.default || c))
export const BowSliderFull = () => import('../../components/molecules/BowSlider/BowSliderFull.vue' /* webpackChunkName: "components/bow-slider-full" */).then(c => wrapFunctional(c.default || c))
export const BowActivitiesList = () => import('../../components/organisms/BowActivitiesList/BowActivitiesList.vue' /* webpackChunkName: "components/bow-activities-list" */).then(c => wrapFunctional(c.default || c))
export const BowContent2Cols = () => import('../../components/organisms/BowContent2Cols/BowContent2Cols.vue' /* webpackChunkName: "components/bow-content2-cols" */).then(c => wrapFunctional(c.default || c))
export const BowContentBlock = () => import('../../components/organisms/BowContentBlock/BowContentBlock.vue' /* webpackChunkName: "components/bow-content-block" */).then(c => wrapFunctional(c.default || c))
export const BowCTABlock = () => import('../../components/organisms/BowCTABlock/BowCTABlock.vue' /* webpackChunkName: "components/bow-c-t-a-block" */).then(c => wrapFunctional(c.default || c))
export const BowCTABlockCenter = () => import('../../components/organisms/BowCTABlock/BowCTABlockCenter.vue' /* webpackChunkName: "components/bow-c-t-a-block-center" */).then(c => wrapFunctional(c.default || c))
export const BowCTABlockSx = () => import('../../components/organisms/BowCTABlock/BowCTABlockSx.vue' /* webpackChunkName: "components/bow-c-t-a-block-sx" */).then(c => wrapFunctional(c.default || c))
export const BowContentBlockDx = () => import('../../components/organisms/BowContentBlockDx/BowContentBlockDx.vue' /* webpackChunkName: "components/bow-content-block-dx" */).then(c => wrapFunctional(c.default || c))
export const BowContentBlockSx = () => import('../../components/organisms/BowContentBlockSx/BowContentBlockSx.vue' /* webpackChunkName: "components/bow-content-block-sx" */).then(c => wrapFunctional(c.default || c))
export const BowContentListMedia = () => import('../../components/organisms/BowContentListMedia/BowContentListMedia.vue' /* webpackChunkName: "components/bow-content-list-media" */).then(c => wrapFunctional(c.default || c))
export const BowContentListMediaDx = () => import('../../components/organisms/BowContentListMedia/BowContentListMediaDx.vue' /* webpackChunkName: "components/bow-content-list-media-dx" */).then(c => wrapFunctional(c.default || c))
export const BowContentListMediaSx = () => import('../../components/organisms/BowContentListMedia/BowContentListMediaSx.vue' /* webpackChunkName: "components/bow-content-list-media-sx" */).then(c => wrapFunctional(c.default || c))
export const BowContentMedia = () => import('../../components/organisms/BowContentMedia/BowContentMedia.vue' /* webpackChunkName: "components/bow-content-media" */).then(c => wrapFunctional(c.default || c))
export const BowContentMediaDx = () => import('../../components/organisms/BowContentMedia/BowContentMediaDx.vue' /* webpackChunkName: "components/bow-content-media-dx" */).then(c => wrapFunctional(c.default || c))
export const BowContentMediaSx = () => import('../../components/organisms/BowContentMedia/BowContentMediaSx.vue' /* webpackChunkName: "components/bow-content-media-sx" */).then(c => wrapFunctional(c.default || c))
export const BowContentMedia2 = () => import('../../components/organisms/BowContentMedia2/BowContentMedia2.vue' /* webpackChunkName: "components/bow-content-media2" */).then(c => wrapFunctional(c.default || c))
export const BowContentMediaDx2 = () => import('../../components/organisms/BowContentMedia2/BowContentMediaDx2.vue' /* webpackChunkName: "components/bow-content-media-dx2" */).then(c => wrapFunctional(c.default || c))
export const BowContentMediaSx2 = () => import('../../components/organisms/BowContentMedia2/BowContentMediaSx2.vue' /* webpackChunkName: "components/bow-content-media-sx2" */).then(c => wrapFunctional(c.default || c))
export const BowContentStickyMedia = () => import('../../components/organisms/BowContentStickyMedia/BowContentStickyMedia.vue' /* webpackChunkName: "components/bow-content-sticky-media" */).then(c => wrapFunctional(c.default || c))
export const BowContentStickyMediaDx = () => import('../../components/organisms/BowContentStickyMedia/BowContentStickyMediaDx.vue' /* webpackChunkName: "components/bow-content-sticky-media-dx" */).then(c => wrapFunctional(c.default || c))
export const BowContentStickyMediaSx = () => import('../../components/organisms/BowContentStickyMedia/BowContentStickyMediaSx.vue' /* webpackChunkName: "components/bow-content-sticky-media-sx" */).then(c => wrapFunctional(c.default || c))
export const BowCover = () => import('../../components/organisms/BowCover/BowCover.vue' /* webpackChunkName: "components/bow-cover" */).then(c => wrapFunctional(c.default || c))
export const BowCoverImmobile = () => import('../../components/organisms/BowCoverImmobile/BowCoverImmobile.vue' /* webpackChunkName: "components/bow-cover-immobile" */).then(c => wrapFunctional(c.default || c))
export const BowCoverPreview = () => import('../../components/organisms/BowCoverPreview/BowCoverPreview.vue' /* webpackChunkName: "components/bow-cover-preview" */).then(c => wrapFunctional(c.default || c))
export const BowCoverSlider = () => import('../../components/organisms/BowCoverSlider/BowCoverSlider.vue' /* webpackChunkName: "components/bow-cover-slider" */).then(c => wrapFunctional(c.default || c))
export const BowDetailsBlock = () => import('../../components/organisms/BowDetailsBlock/BowDetailsBlock.vue' /* webpackChunkName: "components/bow-details-block" */).then(c => wrapFunctional(c.default || c))
export const BowFooter = () => import('../../components/organisms/BowFooter/BowFooter.vue' /* webpackChunkName: "components/bow-footer" */).then(c => wrapFunctional(c.default || c))
export const BowFormContact = () => import('../../components/organisms/BowFormContact/BowFormContact.vue' /* webpackChunkName: "components/bow-form-contact" */).then(c => wrapFunctional(c.default || c))
export const BowFormPropose = () => import('../../components/organisms/BowFormPropose/BowFormPropose.vue' /* webpackChunkName: "components/bow-form-propose" */).then(c => wrapFunctional(c.default || c))
export const BowGallery = () => import('../../components/organisms/BowGallery/BowGallery.vue' /* webpackChunkName: "components/bow-gallery" */).then(c => wrapFunctional(c.default || c))
export const BowHeader = () => import('../../components/organisms/BowHeader/BowHeader.vue' /* webpackChunkName: "components/bow-header" */).then(c => wrapFunctional(c.default || c))
export const BowImmobiliList = () => import('../../components/organisms/BowImmobiliList/BowImmobiliList.vue' /* webpackChunkName: "components/bow-immobili-list" */).then(c => wrapFunctional(c.default || c))
export const BowInfoImmobile = () => import('../../components/organisms/BowInfoImmobile/BowInfoImmobile.vue' /* webpackChunkName: "components/bow-info-immobile" */).then(c => wrapFunctional(c.default || c))
export const BowItemsListBlock = () => import('../../components/organisms/BowItemsListBlock/BowItemsListBlock.vue' /* webpackChunkName: "components/bow-items-list-block" */).then(c => wrapFunctional(c.default || c))
export const BowMediaList = () => import('../../components/organisms/BowMediaList/BowMediaList.vue' /* webpackChunkName: "components/bow-media-list" */).then(c => wrapFunctional(c.default || c))
export const BowNav = () => import('../../components/organisms/BowNav/BowNav.vue' /* webpackChunkName: "components/bow-nav" */).then(c => wrapFunctional(c.default || c))
export const BowReviewsBlock = () => import('../../components/organisms/BowReviewsBlock/BowReviewsBlock.vue' /* webpackChunkName: "components/bow-reviews-block" */).then(c => wrapFunctional(c.default || c))
export const BowReviewsList = () => import('../../components/organisms/BowReviewsList/BowReviewsList.vue' /* webpackChunkName: "components/bow-reviews-list" */).then(c => wrapFunctional(c.default || c))
export const BowTest = () => import('../../components/organisms/BowTest/BowTest.vue' /* webpackChunkName: "components/bow-test" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
