var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list && _vm.list.length > 0)?_c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:['m-menu', 'flex flex-col group w-full']},_vm._l((_vm.list),function(item,index){return _c('BowMenuLink',{key:("menu-item-" + index),class:[
            'm-menu__item',
            'py-3.5 lg:py-5',
            index === _vm.list.length - 1 ? 'lg:hidden' : '',
            'group-hover:opacity-40 hover:opacity-[1_!important]' ],style:({
            opacity: 0,
            transform: 'translateX(-5%)',
        }),attrs:{"href":item.isCustom === true ? item.link : null,"rel":item.isCustom === true ? 'noopener' : null,"target":item.isCustom === true ? '_blank' : null,"title":item.title && item.title !== '' ? item.title : item.label,"to":item.isCustom === false ? _vm.localePath(item.link) : null}},[_vm._v("\n        "+_vm._s(item.label)+"\n    ")])}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }