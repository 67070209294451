import { render, staticRenderFns } from "./BowNavImage.vue?vue&type=template&id=0bfa8cba&"
import script from "./BowNavImage.vue?vue&type=script&lang=js&"
export * from "./BowNavImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowRing: require('/workspace/components/atoms/BowRing/BowRing.vue').default,BowImage: require('/workspace/components/atoms/BowImage/BowImage.vue').default})
