export default async function ($axios, reviews) {
    const list = [];

    await Promise.all(
        reviews.map(async (item) => {
            if (item.id) {
                const singleReviewCall = await $axios.$get(
                    `/posts/${item.id}`,
                    {
                        params: {
                            postType: "recensioni",
                        },
                    }
                );

                if (singleReviewCall.isSuccess === true) {
                    const { posts } = singleReviewCall.payload;
                    const singleReview = posts[0];

                    let author = null;
                    let text = null;
                    let link = null;
                    let image = null;

                    if (
                        singleReview.customFields &&
                        singleReview.customFields.length > 0
                    ) {
                        const authorCf = singleReview.customFields.filter(
                            (el) => el.cf_meta_key === "_autore"
                        );
                        if (
                            authorCf &&
                            authorCf[0] &&
                            authorCf[0].cfv_meta_value
                        ) {
                            author = authorCf[0].cfv_meta_value;
                        }

                        const textCf = singleReview.customFields.filter(
                            (el) => el.cf_meta_key === "_testo"
                        );
                        if (textCf && textCf[0] && textCf[0].cfv_meta_value) {
                            text = textCf[0].cfv_meta_value;
                        }

                        const linkCf = singleReview.customFields.filter(
                            (el) => el.cf_meta_key === "_link"
                        );
                        if (linkCf && linkCf[0] && linkCf[0].cfv_meta_value) {
                            link = {
                                link: linkCf[0].cfv_meta_value,
                                text_link: "Leggi",
                                title_link: "Leggi",
                                type_button: "Link esterno",
                            };
                        }
                    }

                    if (
                        singleReview.featuredImage !== null &&
                        typeof singleReview.featuredImage !== "undefined"
                    ) {
                        image = {
                            alt: singleReview.featuredImage.post_title,
                            id: singleReview.id,
                            image: {
                                height: 1080,
                                provider: "wordpress",
                                url: singleReview.featuredImage.guid,
                                width: 1920,
                            },
                            type: "image",
                        };
                    }

                    if (author && text) {
                        list.push({
                            id: item.id,
                            author,
                            image,
                            link,
                            text,
                        });
                    }
                }
            }
        })
    );

    // console.log(list);
    return list;
}
