//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import devices from "~/helpers/devices";

export default {
    name: "BowMenuLink",

    inheritAttrs: false,

    data: () => ({
        isDesktop: false,
        isActive: false,
    }),

    computed: {
        tag() {
            const attrs = this.$attrs || {};

            if (attrs.to) {
                return "NuxtLink";
            }

            if (attrs.href) {
                return "a";
            }

            return "a";
        },

        listeners() {
            return this.$listeners;
        },
    },

    mounted() {
        this.isDesktop = devices.isDesktop;

        if (this.$el.classList.contains("nuxt-link-exact-active")) {
            this.isActive = true;
        }
    },
};
