export const state = () => ({
    counterLoader: 0,
    fullSliderData: {
        slides: null,
        options: {
            breakpoints: null,
            centeredSlides: false,
            loop: false,
            navigation: true,
            pagination: true,
            slidesPerView: 1,
            spaceBetween: 16,
        },
    },
    fullSliderThumb: null,
    isCoverFirstSection: false,
    isFiltersOpen: false,
    isNavOpen: false,
    isPageImmobili: false,
    isSliderThumbIncluded: false,
    showFullSlider: false,
    window: {
        width: 0,
        height: 0,
    },
});

export const getters = {
    GET_COUNTER_LOADER(state) {
        return state.counterLoader;
    },
    GET_FULL_SLIDER_DATA(state) {
        return state.fullSliderData;
    },
    GET_FULL_SLIDER_THUMB(state) {
        return state.fullSliderThumb;
    },
    GET_IS_COVER_FIRST_SECTION(state) {
        return state.isCoverFirstSection;
    },
    GET_IS_FILTERS_OPEN(state) {
        return state.isFiltersOpen;
    },
    GET_IS_NAV_OPEN(state) {
        return state.isNavOpen;
    },
    GET_IS_PAGE_IMMOBILI(state) {
        return state.isPageImmobili;
    },
    GET_IS_SLIDER_THUMB_INCLUDED(state) {
        return state.isSliderThumbIncluded;
    },
    GET_SHOW_FULL_SLIDER(state) {
        return state.showFullSlider;
    },
    GET_WINDOW(state) {
        return state.window;
    },
};

export const mutations = {
    SET_COUNTER_LOADER(state, value) {
        state.counterLoader = value;
    },
    SET_FULL_SLIDER_DATA(state, value) {
        state.fullSliderData = value;
    },
    SET_FULL_SLIDER_THUMB(state, value) {
        state.fullSliderThumb = value;
    },
    SET_IS_COVER_FIRST_SECTION(state, value) {
        state.isCoverFirstSection = value;
    },
    SET_IS_FILTERS_OPEN(state, value) {
        state.isFiltersOpen = value;
    },
    SET_IS_NAV_OPEN(state, value) {
        state.isNavOpen = value;
    },
    SET_IS_PAGE_IMMOBILI(state, value) {
        state.isPageImmobili = value;
    },
    SET_IS_SLIDER_THUMB_INCLUDED(state, value) {
        state.isSliderThumbIncluded = value;
    },
    SET_SHOW_FULL_SLIDER(state, value) {
        state.showFullSlider = value;
    },
    SET_WINDOW(state, value) {
        state.window = value;
    },
};

export const actions = {
    UPDATE_COUNTER_LOADER({ commit }, counterLoader) {
        commit("SET_COUNTER_LOADER", counterLoader);
    },
    UPDATE_FULL_SLIDER_DATA({ commit }, value) {
        commit("SET_FULL_SLIDER_DATA", value);
    },
    UPDATE_FULL_SLIDER_THUMB({ commit }, value) {
        commit("SET_FULL_SLIDER_THUMB", value);
    },
    UPDATE_IS_COVER_FIRST_SECTION({ commit }, isCoverFirstSection) {
        commit("SET_IS_COVER_FIRST_SECTION", isCoverFirstSection);
    },
    UPDATE_IS_FILTERS_OPEN({ commit }, isFiltersOpen) {
        commit("SET_IS_FILTERS_OPEN", isFiltersOpen);
    },
    UPDATE_IS_NAV_OPEN({ commit }, isNavOpen) {
        commit("SET_IS_NAV_OPEN", isNavOpen);
    },
    UPDATE_IS_PAGE_IMMOBILI({ commit }, value) {
        commit("SET_IS_PAGE_IMMOBILI", value);
    },
    UPDATE_IS_SLIDER_THUMB_INCLUDED({ commit }, value) {
        commit("SET_IS_SLIDER_THUMB_INCLUDED", value);
    },
    UPDATE_SHOW_FULL_SLIDER({ commit }, value) {
        commit("SET_SHOW_FULL_SLIDER", value);
    },
    UPDATE_WINDOW({ commit }, value) {
        commit("SET_WINDOW", value);
    },
};
