import { render, staticRenderFns } from "./BowNav.vue?vue&type=template&id=8c6619cc&"
import script from "./BowNav.vue?vue&type=script&lang=js&"
export * from "./BowNav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowSocials: require('/workspace/components/atoms/BowSocials/BowSocials.vue').default,BowMenu: require('/workspace/components/molecules/BowMenu/BowMenu.vue').default,BowNavImage: require('/workspace/components/molecules/BowNavImage/BowNavImage.vue').default,BowText: require('/workspace/components/atoms/BowText/BowText.vue').default})
