export const state = () => ({
    mapboxKey: null,
});

export const getters = {
    GET_MAPBOX_KEY(state) {
        return state.mapboxKey;
    },
};

export const mutations = {
    SET_MAPBOX_KEY(state, value) {
        state.mapboxKey = value;
    },
};

export const actions = {
    UPDATE_MAPBOX_KEY({ commit }, value) {
        commit('SET_MAPBOX_KEY', value);
    },
};
