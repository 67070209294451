//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowRing",
    props: {
        className: {
            default: null,
            type: [Array, String],
        },
        radius: {
            default: true,
            type: Boolean,
        },
    },
};
