var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"header",tag:"component",class:['o-header', 'overflow-hidden relative w-full z-40']},[_c('div',{class:[
            'o-header__wrapper',
            _vm.getBackground,
            'duration-500 fixed flex h-[100px] items-center justify-between right-0 px-side-spacer py-6 top-0 transition w-full z-1',
            'lg:px-8 xl:px-16' ]},[_c('NuxtLink',{class:[
                'transition duration-300 lg:order-2',
                _vm.isFiltersOpen === true && _vm.isNavOpen === false
                    ? 'opacity-0 xl:opacity-100'
                    : '' ],attrs:{"to":_vm.localePath('/'),"title":"Liberty Homes"}},[_c('BowLogo',{staticClass:"w-[250px] xl:w-[300px]",attrs:{"type":"lateral","hWhite":!_vm.isNavOpen,"white":_vm.logoWhite}})],1),_vm._v(" "),_c('div',{staticClass:"lg:flex lg:items-center lg:justify-start lg:order-1"},[_c('BowMenuToggle',{attrs:{"white":_vm.logoWhite}}),_vm._v(" "),_c('div',{staticClass:"hidden lg:block lg:ml-12 lg:w-[170px]"},[(_vm.list && _vm.list.length > 0 && _vm.list[0])?_c('NuxtLink',{class:[
                        'font-bold hidden',
                        _vm.isNavOpen ? 'lg:hidden' : 'lg:inline' ],attrs:{"to":_vm.localePath(_vm.list[0].link),"title":_vm.list[0].title}},[_vm._v("\n                    "+_vm._s(_vm.list[0].label)+"\n                ")]):_vm._e(),_vm._v(" "),_c('div',{class:[
                        'hidden',
                        _vm.isNavOpen ? 'lg:flex' : 'lg:hidden',
                        'lg:items-center lg:h-full' ]},[_c('NuxtLink',{attrs:{"to":_vm.localePath('/', 'it'),"title":"IT"}},[_vm._v("\n                        IT\n                    ")]),_vm._v(" "),_c('span',{staticClass:"inline-block mx-4"},[_vm._v("|")]),_vm._v(" "),_c('NuxtLink',{attrs:{"to":_vm.localePath('/', 'en'),"title":"EN"}},[_vm._v("\n                        EN\n                    ")])],1)],1)],1),_vm._v(" "),(_vm.checkBreakpointUpLg)?_c('div',{class:[
                'hidden lg:flex lg:items-center lg:justify-end lg:order-3' ]},[(_vm.list && _vm.list.length > 0 && _vm.list[1])?_c('NuxtLink',{class:[
                    'font-bold hidden lg:inline',
                    _vm.isNavOpen ? 'lg:invisible' : '',
                    'lg:mr-12' ],attrs:{"title":_vm.list[1].title,"to":_vm.localePath(_vm.list[1].link)}},[_vm._v("\n                "+_vm._s(_vm.list[1].label)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.list && _vm.list.length > 0 && _vm.list[2])?_c('BowButton',{attrs:{"type":"link-ext","href":_vm.list[2].link,"theme":"secondary","uppercase":true,"title":_vm.list[2].title}},[_vm._v("\n                "+_vm._s(_vm.list[2].label)+"\n            ")]):_vm._e()],1):_vm._e()],1),_vm._v(" "),_c('BowNav',{attrs:{"isOpen":_vm.isNavOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }