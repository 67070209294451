export default function (seo, currentUrl, isInternal = true) {
    const headToReturn = {};
    headToReturn.meta = [];

    if (seo.title) {
        headToReturn.title = seo.title;

        if (isInternal === true) {
            headToReturn.title = `${seo.title} | Liberty Homes`;
        }
    }

    if (seo.description) {
        headToReturn.meta.push({
            hid: "description",
            name: "description",
            content: seo.description,
        });
    }

    if (seo.is_robots_nofollow) {
        headToReturn.meta.push({
            hid: "robots",
            name: "robots",
            content: "no-follow",
        });
    }

    if (seo.open_graph_title) {
        headToReturn.meta.push({
            hid: "og:title",
            property: "og:title",
            content: seo.open_graph_title,
        });
    }

    if (seo.open_graph_description) {
        headToReturn.meta.push({
            hid: "og:description",
            property: "og:description",
            content: seo.open_graph_description,
        });
    }

    headToReturn.meta.push({
        hid: "og:url",
        property: "og:url",
        content: currentUrl,
    });

    headToReturn.meta.push({
        hid: "og:site_name",
        property: "og:site_name",
        content: "Liberty Homes",
    });

    if (seo.open_graph_image) {
        headToReturn.meta.push({
            hid: "og:image",
            property: "og:image",
            content: seo.open_graph_image,
        });
    }

    if (seo.open_graph_image_meta) {
        const ogImageMeta = JSON.parse(seo.open_graph_image_meta);

        if (Object.prototype.hasOwnProperty.call(ogImageMeta, "width")) {
            headToReturn.meta.push({
                hid: "og:image:width",
                property: "og:image:width",
                content: ogImageMeta.width,
            });
        }

        if (Object.prototype.hasOwnProperty.call(ogImageMeta, "height")) {
            headToReturn.meta.push({
                hid: "og:image:height",
                property: "og:image:height",
                content: ogImageMeta.height,
            });
        }

        if (Object.prototype.hasOwnProperty.call(ogImageMeta, "type")) {
            headToReturn.meta.push({
                hid: "og:image:type",
                property: "og:image:type",
                content: ogImageMeta.type,
            });
        }
    }

    if (seo.twitter_title) {
        headToReturn.meta.push({
            hid: "twitter:title",
            property: "twitter:title",
            content: seo.twitter_title,
        });
    }

    if (seo.twitter_description) {
        headToReturn.meta.push({
            hid: "twitter:description",
            property: "twitter:description",
            content: seo.twitter_description,
        });
    }

    if (seo.twitter_image) {
        headToReturn.meta.push({
            hid: "twitter:image",
            property: "twitter:image",
            content: seo.twitter_image,
        });
    }

    return headToReturn;
}
