//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { devices } from "~/helpers";

export default {
    name: "BowMenuToggle",
    props: {
        white: {
            default: true,
            type: Boolean,
        },
    },
    data: () => ({}),
    computed: {
        ...mapState({
            isNavOpen: ({ ui }) => ui.isNavOpen,
        }),

        ...mapGetters(["ui/GET_IS_NAV_OPEN"]),

        state() {
            if (this.isNavOpen === true) {
                return "open";
            }

            return "close";
        },

        getCommonClasses() {
            return [
                "border-t-2",
                this.white === true ? "border-t-white" : "border-t-primary",
                "h-[0px] rounded-sm transition-transform w-10",
            ];
        },
    },
    methods: {
        ...mapActions(["ui/UPDATE_IS_NAV_OPEN"]),

        handleMenuToggle() {
            this.$store.dispatch("ui/UPDATE_IS_NAV_OPEN", !this.isNavOpen);

            if (devices.isMobile) {
                document
                    .querySelector("body")
                    .classList.toggle("overflow-y-hidden");
            }

            if (devices.isDesktop) {
                document
                    .querySelector("body")
                    .classList.remove("overflow-y-hidden");
            }
        },
    },
};
