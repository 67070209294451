// import {
//     breakpointUp,
//     breakpointDown,
//     breakpointBetween
// } from '../helpers/checkBreakpoint';

export default ({ app, $axios, store }, inject) => {
    // Expose utilities for check current breakpoint
    // inject('checkBreakpoint', {
    //     up: (breakpoint) => breakpointUp(breakpoint),
    //     down: (breakpoint) => breakpointDown(breakpoint),
    //     between: (breakpointSmall, breakpointBig) =>
    //         breakpointBetween(breakpointSmall, breakpointBig)
    // });

    inject("getIDBySlug", (slug) => {
        // Get all posts
        let posts = [];

        posts = [...store.getters["posts/GET_POSTS"]];

        let id = null;

        const matchedPost = posts.filter((item, idx) => {
            return item.slug === slug;
        });

        if (matchedPost && matchedPost[0]) {
            if (matchedPost[0].id && matchedPost[0].id !== "") {
                id = matchedPost[0].id;

                return id;
            }
        }

        return id;
    });

    inject("getSlugByID", (id) => {
        // Get all posts
        let posts = [];

        posts = [...store.getters["posts/GET_POSTS"]];

        let slug = null;

        const matchedPost = posts.filter((item, idx) => {
            return item.id === id;
        });

        if (matchedPost && matchedPost[0]) {
            if (matchedPost[0].slug && matchedPost[0].slug !== "") {
                slug = matchedPost[0].slug;

                return slug;
            }
        }

        return slug;
    });
};
