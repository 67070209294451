var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.image && _vm.image.url && _vm.image.url !== '')?_c('picture',{ref:"element",class:[
          _vm.classPicture ? _vm.classPicture : ['h-auto relative w-full'],
          _vm.radius ? 'rounded-media' : '' ]},[_c('img',{class:[
              'lazyload',
              _vm.classImage
                  ? _vm.classImage
                  : ['h-auto object-cover relative w-full'],
              _vm.radius ? 'rounded-media' : '',
              _vm.classImageExtra ? _vm.classImageExtra : '' ],style:(Object.assign({}, _vm.styleImage)),attrs:{"alt":_vm.alt && _vm.alt !== '' ? _vm.alt : _vm.image.alt,"src":_vm.getSrc}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }