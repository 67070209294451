//
//
//
//
//
//

import tailwindConf from "#tailwind-config";

export default {
    name: "BowText",
    props: {
        align: {
            default: "",
            type: String,
        },
        size: {
            default: "",
            type: String,
        },
    },
    data: () => ({}),
    computed: {
        getSize() {
            let size = "text-base xl:text-xl";

            // Manage size of text
            if (this.size !== "") {
                Object.entries(tailwindConf.theme.fontSize).forEach(
                    ([key, value]) => {
                        if (this.size === key) {
                            switch (this.size) {
                                case "xs":
                                    size = "text-xs";
                                    break;
                                case "sm":
                                    size = "text-sm";
                                    break;
                                case "base":
                                    size = "text-base";
                                    break;
                                case "lg":
                                    size = "text-lg";
                                    break;
                                case "xl":
                                    size = "text-xl";
                                    break;
                                case "2xl":
                                    size = "text-2xl";
                                    break;
                                case "3xl":
                                    size = "text-3xl";
                                    break;
                                case "4xl":
                                    size = "text-4xl";
                                    break;
                                case "5xl":
                                    size = "text-5xl";
                                    break;
                                case "6xl":
                                    size = "text-4xl 2xl:text-6xl";
                                    break;
                                case "7xl":
                                    size = "text-5xl 2xl:text-7xl";
                                    break;
                                case "8xl":
                                    size = "text-6xl 2xl:text-8xl";
                                    break;
                                default:
                                    size = "text-base xl:text-xl";
                                    break;
                            }
                        }
                    }
                );
            }

            return size;
        },
    },
};
