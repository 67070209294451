import { render, staticRenderFns } from "./BowHeader.vue?vue&type=template&id=461d3ad6&"
import script from "./BowHeader.vue?vue&type=script&lang=js&"
export * from "./BowHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowLogo: require('/workspace/components/atoms/BowLogo/BowLogo.vue').default,BowMenuToggle: require('/workspace/components/atoms/BowMenuToggle/BowMenuToggle.vue').default,BowButton: require('/workspace/components/atoms/BowButton/BowButton.vue').default,BowNav: require('/workspace/components/organisms/BowNav/BowNav.vue').default})
