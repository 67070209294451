export const state = () => ({
    businessData: null,
    social: {
        url_facebook: "",
        url_instagram: "",
        url_youtube: "",
    },
});

export const getters = {
    GET_BUSINESS_DATA(state) {
        return state.businessData;
    },

    GET_SOCIAL(state) {
        return state.social;
    },

    CHECK_OPTIONS(state) {
        return !(state.social === null || state.businessData === null);
    },
};

export const mutations = {
    SET_BUSINESS_DATA(state, value) {
        state.businessData = value;
    },

    SET_SOCIAL(state, value) {
        state.social = value;
    },
};

export const actions = {
    async UPDATE_OPTIONS({ commit, state }, value) {
        if (state.businessData !== null && state.social !== null) {
            return;
        }

        try {
            const optionsAPI = await this.$axios.$get("/options");

            if (optionsAPI.isSuccess === false) {
                return;
            }

            if (optionsAPI.isSuccess === true && optionsAPI.payload) {
                const businessData = {};
                const social = {};

                if (optionsAPI.payload.nrOptions > 0) {
                    const businessDataFiltered = [
                        ...optionsAPI.payload.options,
                    ].filter((el, idx) => {
                        return (
                            String(el.option_name).search(/^bow_setting_/) !==
                            -1
                        );
                    });

                    businessDataFiltered.forEach((item) => {
                        if (item.option_name) {
                            const key = item.option_name.replace(
                                "bow_setting_",
                                ""
                            );
                            businessData[key] = item.option_value;
                        }
                    });

                    const socialFiltered = businessDataFiltered.filter(
                        (el, idx) => {
                            return (
                                String(el.option_name).search(
                                    /^bow_setting_url_/
                                ) !== -1
                            );
                        }
                    );

                    socialFiltered.forEach((item) => {
                        if (item.option_name) {
                            const key = item.option_name.replace(
                                "bow_setting_",
                                ""
                            );
                            social[key] = item.option_value;
                        }
                    });
                }

                commit("SET_BUSINESS_DATA", businessData);
                commit("SET_SOCIAL", social);
            }
        } catch (e) {
            console.error(e);
        }
    },
};
