//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowNavImage",

    props: {},

    data: () => ({
        image: null,
    }),

    fetch() {
        // const baseURL = this.$config.BASE_URL || "https://www.libertyhomes.it/";
        const cmsBaseURL = this.$config.CMS_BASE_URL || "https://www.libertyhomes.it/cms/"

        this.image = {
            alt: "Liberty Homes",
            height: 1080,
            provider: "wordpress",
            url: `${cmsBaseURL}wp-content/uploads/2022/04/cover.jpg`,
            width: 1920,
        };
    },
};
