export default function (value) {
    let isOk = false;

    const bowColors = ["primary", "secondary", "white"];

    Object.entries(bowColors).forEach(([key, val]) => {
        if (value === val) {
            isOk = true;
        }
    });

    return isOk;
}
