//
//
//
//
//
//
//
//
//
//

import { themeValidation } from "../../../helpers/propsValidators";
import { getSectionTheme } from "../../../helpers/getTheme/getTheme";

export default {
    name: "BowSection",
    props: {
        htmlTag: {
            default: null,
            type: String,
        },
        spacer: {
            default: "base",
            type: String,
        },
        theme: {
            default: null,
            type: String,
            validation: (value) => themeValidation(value),
        },
    },
    computed: {
        getSpacer() {
            switch (this.spacer) {
                case "none":
                    return "";
                case "base":
                default:
                    return "mt-section-spacer 2xl:mt-section-spacer-desktop";
            }
        },
        getTheme() {
            if (this.theme && this.theme !== "") {
                return getSectionTheme(this.theme);
            }

            return "text-dark";
        },
    },
};
